import React, { type ReactNode, type ComponentType } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";

import { userPlanSelector } from "src/store/system/selector";

import { USER_PLAN as UserPlan } from "src/enums/common.enum";

import { StatefulLoadableIconButton } from "src/components/LoadableIconButton";

function PlatformIntegrationsTableRow({
  name,
  username,
  platform,
  isRunning,
  sync,
  inventory,
  onImport,
  onSyncChanged,
  onPullDataNow,
  hideInventory,
  onAdd,
  onDelete,
  AddComponent,
}: {
  name: string;
  username?: string;
  platform: ReactNode;
  isRunning: boolean;
  sync: boolean;
  inventory: boolean;
  hideInventory: boolean;
  onImport: () => void;
  onSyncChanged: (sync: boolean, inventory?: boolean) => void;
  onPullDataNow: () => Promise<void>;
  onAdd?: () => void;
  onDelete?: () => void;
  AddComponent?: ComponentType;
}) {
  const plan = useSelector(userPlanSelector) || UserPlan.BASIC;
  const planUltimate = plan === UserPlan.ULTIMATE;
  const importButton = (
    <Button color="primary" onClick={() => onImport()} disabled={isRunning}>
      Import
    </Button>
  );
  const syncButton = (
    <StatefulLoadableIconButton
      onClick={async () => {
        if (!planUltimate) {
          toast.error(
            "Only ultimate users are allowed to turn on Daily Integration. Please upgrade to unlock this feature"
          );
          return;
        }
        await onPullDataNow();
      }}
      color="primary"
      disabled={!sync || isRunning}
    >
      <SyncIcon />
    </StatefulLoadableIconButton>
  );
  const handleSyncChanged = (s: boolean, i?: boolean) => {
    if (!planUltimate) {
      toast.error(
        "Only ultimate users are allowed to turn on Daily Integration. Please upgrade to unlock this feature"
      );
      return;
    }

    onSyncChanged(s, i);
  };

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{username || ""}</TableCell>
      <TableCell>{platform}</TableCell>
      <TableCell align="center">
        {isRunning ? (
          <Tooltip title="Your import is currently processing.">
            <div>{importButton}</div>
          </Tooltip>
        ) : (
          importButton
        )}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={planUltimate && (sync || false)}
          onChange={(ev) => handleSyncChanged(ev.target.checked)}
        />
      </TableCell>
      {hideInventory ? null : (
        <TableCell align="center">
          <Checkbox
            disabled={!sync}
            checked={planUltimate && sync && inventory !== false}
            onChange={(ev) =>
              handleSyncChanged(sync || false, ev.target.checked)
            }
          />
        </TableCell>
      )}
      <TableCell align="center">
        {isRunning ? (
          <Tooltip title="Your import is currently processing.">
            <div>{syncButton}</div>
          </Tooltip>
        ) : (
          syncButton
        )}
      </TableCell>
      <TableCell align="center">
        {AddComponent ? (
          <AddComponent />
        ) : onAdd ? (
          <IconButton color="primary" onClick={() => onAdd()}>
            <AddIcon />
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell align="center">
        {onDelete ? (
          <IconButton color="secondary" onClick={() => onDelete()}>
            <DeleteIcon />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
}

export default PlatformIntegrationsTableRow;
