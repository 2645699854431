import dayjs from "dayjs";
import { createSelector } from "reselect";
import { AppState } from "..";
import { getInventory } from "../inventory/selector";
import { SALE_FILTER, SaleFilter } from "../../enums";
import { GradientsByName } from "src/components/SvgGradientDef";
import { getVendors } from "src/store/vendor/selector";
import { DefaultFilterModel, filter } from "src/utils/filters";

import type { Sale } from "src/interfaces/sale.interface";
import type { SaleState } from "src/interfaces/saleState.interface";

const SalesFilterDefinition = {
  id: "string" as const,
  transaction_id: "string" as const,
  item_title: "string" as const,
  department: "string" as const,
  category: "string" as const,
  sub_category: "string" as const,
  vendor: {
    operator: "string" as const,
    column: "vendorName" as const,
  },
  brand: "string" as const,
  location: "string" as const,
  sku: "string" as const,
  purchase_date: "date" as const,
  list_date: "date" as const,
  return_date: "date" as const,
  sale_date: "date" as const,
  days_on_platform: "number" as const,
  sale_price: "number" as const,
  purchase_price: "number" as const,
  shipping_cost: "number" as const,
  shipping_cost_analytics: "number" as const,
  extra_shipping_cost: "number" as const,
  transaction_fees: "number" as const,
  other_fees: "number" as const,
  extra_transaction_fees: "number" as const,
  gross_profit: "number" as const,
  sale_state: "string" as const,
  sales_tax: "number" as const,
  liable_to_pay: "boolean" as const,
  platforms_listed: "array_string" as const,
  sale_platform: "string" as const,
  notes: "string" as const,
  status: "string" as const,
};

const getSaleState = (state: AppState) => state.sale;

const now = dayjs();

const applyTimeFilter = (filter: SALE_FILTER | SaleFilter, date) => {
  if (filter === SALE_FILTER.ALL_TIME) {
    return true;
  }

  if (!date) {
    return false;
  }

  if ((filter as SaleFilter)?.filter === SALE_FILTER.CUSTOM) {
    const f = filter as SaleFilter;
    return f.period.start <= date && date <= f.period.end;
  }

  if (now.isSame(date, "day")) {
    return true;
  }

  if (filter === SALE_FILTER.DAY) {
    return false;
  }
  if (filter === SALE_FILTER.WEEK) {
    if (now.isSame(date, "week")) {
      return true;
    }
    return false;
  }
  if (now.isSame(date, "month")) {
    return true;
  }
  if (filter === SALE_FILTER.MONTH) {
    return false;
  }
  if (now.isSame(date, "year")) {
    return true;
  }

  return false;
};

export const salesSelector = createSelector(
  getSaleState,
  (state: SaleState) => state
);
export const salesFilterSelector = createSelector(
  salesSelector,
  (state: SaleState) => state.filter || DefaultFilterModel
);
export const getAllSales = createSelector(
  [getSaleState, getVendors],
  (state, vendors) =>
    state.items.map((item) => {
      if (item.vendor)
        return {
          ...item,
          vendorName: vendors.find((v) => v.id === item.vendor)?.name || "",
        };
      return item;
    })
);
export const salesTimeFilterSelector = createSelector(
  getSaleState,
  (state) => state.timeFilter
);
const allSalesTimeFilteredSelector = createSelector(
  [getAllSales, salesTimeFilterSelector],
  (items, timeFilter) =>
    items.filter((item) =>
      item.is_return || item.original_id
        ? applyTimeFilter(timeFilter, item.return_date)
        : applyTimeFilter(timeFilter, item.sale_date)
    )
);

const allVisibleSalesSelector = createSelector(
  [allSalesTimeFilteredSelector, salesFilterSelector],
  (sales, model) => {
    return filter(
      SalesFilterDefinition,
      sales as (Sale & { vendorName: string })[],
      model
    );
  }
);
export const visibleSalesSelector = createSelector(
  allVisibleSalesSelector,
  (items): Sale[] =>
    items.filter((item) => !item.original_id && !item.is_return)
);
export const visibleReturnsSelector = createSelector(
  allVisibleSalesSelector,
  (items): Sale[] =>
    items.filter((item) => !!item.original_id || !!item.is_return)
);

export const getSales = createSelector(
  [getAllSales, salesTimeFilterSelector],
  (items, timeFilter) =>
    items.filter(
      (item) =>
        !item.original_id &&
        !item.is_return &&
        applyTimeFilter(timeFilter, item.sale_date)
    )
);

export const getTimeFilteredInventory = createSelector(
  [getInventory, getAllSales, salesTimeFilterSelector],
  (inventory, sales, timeFilter) =>
    [
      ...inventory,
      ...sales.filter((s) => !s.return_id && !s.original_id && !s.is_return),
    ].filter((item) => applyTimeFilter(timeFilter, item.purchase_date))
);

export const getPendingSalesUploads = createSelector(
  getSaleState,
  (state) => state.pendingUploads
);
export const getUnmatchedUploads = createSelector(
  getPendingSalesUploads,
  (sales) => sales.filter((item) => item.unmatched)
);
export const getUploadFilename = createSelector(
  getSaleState,
  (state) => state.uploadFilename
);
export const getMatchedInventoryIds = createSelector(
  getPendingSalesUploads,
  (sales) => {
    return sales.reduce((acc, sale) => {
      if (!sale.unmatched && sale.inventoryId)
        acc[sale.inventoryId] = (acc[sale.inventoryId] || 0) + 1;

      return acc;
    }, {});
  }
);

export const getMatchableInventory = createSelector(
  [getInventory, getMatchedInventoryIds],
  (inventory, matchedIds) =>
    inventory.filter(
      (item) => (item.quantity || 1) > (matchedIds[item.id] || 0)
    )
);
export const getIsEbayImport = createSelector(
  getSaleState,
  (state) => state.ebayUploads
);

/* XXX: this isnt used, I believe it was copied into the Sales page
export const getSalesStats = createSelector([getSales, getTimeFilteredInventory], (sales, inventory) => {
  const unreturnedSales = sales.filter((sale) => !sale.return_id);
  const grossProfit = unreturnedSales.reduce((result, sale) => result + sale.gross_profit, 0);
  const totalSalePrice = unreturnedSales.reduce((result, sale) => result + sale.sale_price, 0);
  return {
    grossProfit,
    averageSalePrice: totalSalePrice / unreturnedSales.length,
    sellThroughRate: !!inventory.length ? unreturnedSales.length / inventory.length * 100 : "N/A",
  };
});
*/

export const salesSummarySelector = createSelector(getSales, (sales) => {
  let departments: string[] = Array.from(
    new Set(sales.map((e) => e.department || "Uncategorized"))
  );
  let total = 0;
  let otherTotal = 0;
  let spByDepartments = departments.map((department) => {
    const filteredSales = sales.filter(
      (e) =>
        e.department === department ||
        (department === "Uncategorized" && !e.department)
    );
    let sp = 0;
    filteredSales.forEach((e) => {
      sp += e.sale_price * 1.0;
      total += e.sale_price * 1.0;
    });
    return sp;
  });
  const otherIndices = new Set<number>([]);
  spByDepartments.forEach((pp, index) => {
    if (pp <= total * 0.05) {
      otherIndices.add(index);
      otherTotal += pp;
    }
  });
  if (otherIndices.size) {
    spByDepartments = spByDepartments.filter(
      (pp, index) => !otherIndices.has(index)
    );
    spByDepartments.push(otherTotal);
    departments = departments.filter(
      (department, index) => !otherIndices.has(index)
    );
    departments.push("Other");
  }

  const pair = departments.reduce((acc, label, i) => {
    const value = spByDepartments[i];
    acc.push({ value, label });
    return acc;
  }, [] as { value: number; label: string }[]);

  pair.sort((a, b) => b.value - a.value);

  return [
    {
      values: pair.map((p) => p.value),
      labels: pair.map((p) => p.label),
      type: "pie",
      marker: {
        colors: [
          GradientsByName.Green.color,
          GradientsByName.LightGreen.color,
          GradientsByName.Blue.color,
          GradientsByName.LightBlue.color,
          GradientsByName.Grey.color,
          GradientsByName.LightGrey.color,
        ],
      },
      hovertemplate:
        "%{label}<br />%{value:$,.2f}<br />%{percent}<extra></extra>",
      hole: 0.4,
    },
  ];
});

export const getEarliestSaleYear = createSelector(getSales, (sales) => {
  const minDate = sales.reduce((result, item) => {
    if (!item.sale_date) {
      return result;
    }
    const saleDate = new Date(item.sale_date);
    if (isNaN(saleDate.valueOf())) {
      return result;
    }
    if (saleDate < result) {
      return saleDate;
    }
    return result;
  }, new Date());
  return +minDate.getFullYear();
});

export const getEbayExtraRows = createSelector(getSaleState, (state) => ({
  extraRows: state.ebayExtraRows,
  returns: state.ebayReturns,
}));

export const getUnreviewedSales = createSelector(getAllSales, (items) =>
  items.filter((item) => !item.is_return && !!item.unreviewed)
);

export const getSaleById = createSelector(
  [getAllSales, (_, id) => id],
  (items, itemId) => {
    if (!itemId) return undefined;
    let sale = items.find(({ id }) => id === itemId);
    if (sale && !(sale.is_return || sale.original_id) && !sale.return_id) {
      const return_sale = items.find(
        ({ original_id }) => original_id === itemId
      );
      if (return_sale) {
        sale = { ...sale, return_id: return_sale.id };
      }
    }

    return sale;
  }
);
export const getSaleByOriginalId = createSelector(
  [getAllSales, (_, id) => id],
  (items, itemId) =>
    itemId ? items.find(({ original_id }) => original_id === itemId) : undefined
);

export const getUnreviewedReturnSales = createSelector(getAllSales, (items) =>
  items.filter((item) => item.is_return && !!item.unreviewed)
);

export const getSalesLoading = createSelector(
  salesSelector,
  ({ loading }) => loading
);
