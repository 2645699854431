import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { useFormik } from "formik";
import {
  DndContext,
  DragOverlay,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  scheduleCAccountsSelector,
  scheduleCNotMappedGeneralLedgerAccountsSelector,
} from "src/store/glAccounts/selector";
import {
  scheduleCGroupingsSelector,
  scheduleCMappingsSelector,
} from "src/store/system/selector";
import { updateScheduleCMappings } from "src/apiService/modules/users";
import type { ExpenseType } from "src/interfaces/systemState.interface";

interface ScheduleCMappingsDialogContentProps {
  generalLedgerAccounts: { "General Ledger Account": string; id?: string }[];
  onSubmit?: () => void;
}

interface Values {
  step: "mappings" | "groupings";
  mappings: Record<string, ExpenseType>;
  groupings: { name: string; entries: string[] }[];
}

const InitialValues: Record<string, ExpenseType> = {
  "Bank Fees": "comissions-and-fees",
  "Car Expenses": "car-and-truck-expenses",
  "Cell Phone": "other-expenses",
  "Contract Work": "contract-labor",
  Donations: "other-expenses",
  Equipment: "other-expenses",
  Internet: "other-expenses",
  "Listing/Platform Fees": "comissions-and-fees",
  "Non-Sourcing Mileage/Transportation": "car-and-truck-expenses",
  "Office Supplies": "office-expense",
  Rent: "rent-other-business-property",
  "Shipping Fees": "office-expense",
  "Shipping Supplies": "supplies",
  "Sourcing Mileage/Transportation": "car-and-truck-expenses",
  "Subscription/Dues": "other-expenses",
  Travel: "travel-and-meals-travel",
  Utilities: "utilities",
  "Inventory Purchases": "supplies",
  "Sales - Shipping Costs": "office-expense",
  "Sales - Transaction Fees": "comissions-and-fees",
};

export const ScheduleCLineOptions: {
  text: string;
  entry: ExpenseType;
}[] = [
  {
    text: "Line 8 - Advertising",
    entry: "advertising",
  },
  {
    text: "Line 9 - Car and truck expenses",
    entry: "car-and-truck-expenses",
  },
  {
    text: "Line 10 - Commissions and fees",
    entry: "comissions-and-fees",
  },
  {
    text: "Line 11 - Contract labor",
    entry: "contract-labor",
  },
  {
    text: "Line 12 - Depletion",
    entry: "depletion",
  },
  {
    text: "Line 13 - Depreciation and section 179 expense deduction",
    entry: "depreciation-and-section-179-expense-deduction",
  },
  {
    text: "Line 14 - Employee benefit programs",
    entry: "employee-benefit-programs",
  },
  {
    text: "Line 15 - Insurance",
    entry: "insurance",
  },
  {
    text: "Line 16a - Interest: Mortage",
    entry: "interest-mortage",
  },
  {
    text: "Line 16b - Interest: Other",
    entry: "interest-other",
  },
  {
    text: "Line 17 - Legal and professional services",
    entry: "legal-and-professional-services",
  },
  {
    text: "Line 18 - Office expense",
    entry: "office-expense",
  },
  {
    text: "Line 19 - Pension and profit-sharing plans",
    entry: "pension-and-profit-sharing-plans",
  },
  {
    text: "Line 20a - Rent: Vehicles, machinery, and equipment",
    entry: "rent-vehicles-machinery-and-equipment",
  },
  {
    text: "Line 20b - Rent: Other business property",
    entry: "rent-other-business-property",
  },
  {
    text: "Line 21 - Repairs and maintenance",
    entry: "repairs-and-maintenance",
  },
  {
    text: "Line 22 - Supplies",
    entry: "supplies",
  },
  {
    text: "Line 23 - Taxes and licenses",
    entry: "taxes-and-licenses",
  },
  {
    text: "Line 24a - Travel and meals: Travel",
    entry: "travel-and-meals-travel",
  },
  {
    text: "Line 24b - Travel and meals: Deductible meals",
    entry: "travel-and-meals-deductible-meals",
  },
  {
    text: "Line 25 - Utilities",
    entry: "utilities",
  },
  {
    text: "Line 26 - Wages",
    entry: "wages",
  },
  {
    text: "Line 27a - Other expenses",
    entry: "other-expenses",
  },
  {
    text: "Line 27b - Energy efficient commercial bldgs deduction",
    entry: "energy-efficient-commercial-bldgs-deduction",
  },
];

function sortArray<T>(array: T[], f: (a: T, b: T) => number) {
  array.sort(f);
  return array;
}

function DraggableButton({ id, children }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });
  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
    display: "block",
    margin: "8px 0",
    zIndex: "99999999",
  };

  return (
    <Button
      ref={setNodeRef}
      style={style}
      variant="outlined"
      {...listeners}
      {...attributes}
    >
      {children}
    </Button>
  );
}

const useGroupingFieldStyles = makeStyles((theme) => ({
  root: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: theme.spacing(0.5),
    margin: theme.spacing(1, 0),
    display: "flex",
    flexWrap: "wrap",
    minHeight: 50,
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
    overflow: "hidden",
  },
  rootDragging: {
    borderColor: `${theme.palette.primary.main}!important`,
    borderWidth: 2,
    padding: "3px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function GroupingField({
  label,
  value,
  values,
  onChange,
  onDelete,
  error,
  id,
}: {
  label: string;
  values: string[];
  onDelete: (key: string) => void;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  id: string | number;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  const classes = useGroupingFieldStyles();
  return (
    <div ref={setNodeRef}>
      <TextField
        label={label}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        fullWidth
        error={!!error}
        helperText={error}
      />
      <div className={clsx(classes.root, isOver && classes.rootDragging)}>
        {values.map((v) => (
          <Chip
            key={v}
            className={classes.chip}
            label={v}
            onDelete={() => onDelete(v)}
          />
        ))}
      </div>
    </div>
  );
}

const useScheduleCMappingsDialogContentStyles = makeStyles((theme) => ({
  groupingGrid: {
    position: "relative",
    minHeight: "65vh",
  },
  groupingGridInner: {
    position: "absolute",
    inset: 0,
    padding: theme.spacing(1),
    overflowY: "auto",
  },
}));

function ScheduleCMappingsDialogContent({
  generalLedgerAccounts,
  onSubmit,
}: ScheduleCMappingsDialogContentProps) {
  const classes = useScheduleCMappingsDialogContentStyles();
  const store = useStore();
  const scheduleCMappings = useSelector(scheduleCMappingsSelector);
  const scheduleCInitialGroupings = useSelector(scheduleCGroupingsSelector);
  const sortedGeneralLedgerAccounts = useMemo(() => {
    const gl = generalLedgerAccounts ? [...generalLedgerAccounts] : [];
    gl.sort((a, b) =>
      a["General Ledger Account"].localeCompare(b["General Ledger Account"])
    );
    return gl;
  }, [generalLedgerAccounts]);
  const initialValues = useMemo(() => {
    const groupings = Object.entries(
      Object.entries(scheduleCInitialGroupings).reduce(
        (groupings, [key, value]) => {
          if (!groupings[value]) groupings[value] = [];
          groupings[value].push(key);
          return groupings;
        },
        {} as Record<string, string[]>
      )
    ).map(([name, entries]) => ({
      name,
      entries,
    }));

    if (groupings.length < 9) {
      for (let i = groupings.length; i < 9; i++) {
        groupings.push({
          name: "",
          entries: [],
        });
      }
    }
    return {
      step: generalLedgerAccounts.length > 0 ? "mappings" : "groupings",
      mappings: generalLedgerAccounts.reduce((v, gl) => {
        const account = gl["General Ledger Account"];
        v[account] =
          scheduleCMappings[account] ||
          InitialValues[account] ||
          "other-expenses";
        return v;
      }, {} as Values["mappings"]),
      groupings,
    } as Values;
  }, [generalLedgerAccounts, scheduleCMappings, scheduleCInitialGroupings]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: Record<string, string> = {};
      for (const [key, value] of Object.entries(values)) {
        if (!value) errors[`mapping.${key}`] = "Required field!";
      }

      if (values.step === "groupings") {
        const scheduleCMappings = scheduleCMappingsSelector(store.getState());
        const mappings = { ...scheduleCMappings, ...values.mappings };
        const trailing = Object.entries(mappings).filter(
          ([k, v]) =>
            v === "other-expenses" &&
            !values.groupings.some((grouping) =>
              grouping.entries.some((entry) => entry === k)
            )
        );

        if (trailing.length) {
          errors["groupings.mappings"] =
            "You must group all the general ledger accounts";
        }

        for (let i = 0; i < values.groupings.length; i++) {
          const grouping = values.groupings[i];
          if (grouping.entries.length > 0 && !grouping.name?.trim())
            errors[`groupings[${i}].name`] = "Required Field";
        }
      }

      return errors;
    },
    onSubmit: async (values, { setValues }) => {
      if (values.step === "mappings") {
        let count = 0;
        const realMappings = {
          ...scheduleCMappingsSelector(store.getState()),
          ...values.mappings,
        };
        for (const mapping of Object.values(realMappings)) {
          if (mapping === "other-expenses") {
            count++;
            if (count > 9) {
              setValues((v) => {
                return {
                  ...v,
                  groupings: v.groupings.map((group) => {
                    return {
                      ...group,
                      entries: group.entries.filter(
                        (v) => realMappings[v] === "other-expenses"
                      ),
                    };
                  }),
                  step: "groupings",
                };
              }, false);
              return;
            }
          }
        }
      }
      const groupings = values.groupings.reduce((groupings, grouping) => {
        for (const account of grouping.entries) {
          groupings[account] = grouping.name;
        }
        return groupings;
      }, {} as Record<string, string>);

      await updateScheduleCMappings(values.mappings, groupings);

      if (onSubmit) onSubmit();
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      {...({ onSubmit: formik.handleSubmit } as any)}
    >
      {formik.values.step === "groupings" ? (
        <>
          <DialogContent>
            <DialogContentText>
              There are 9 spaces on the Schedule C for other expenses that don’t
              fall into one of the other lines included. You have more than 9
              general ledger accounts mapped to this area so some of these will
              need to be grouped together.
            </DialogContentText>
            <DialogContentText>
              Review the accounts below and drag/drop to create groupings for
              the other expenses section of the Schedule C.
            </DialogContentText>
            <Grid container>
              <DndContext
                onDragEnd={({ active, over }) => {
                  const index = over?.id;
                  const value = active?.id;
                  if (index === undefined || value === undefined) return;

                  formik.setValues((v) => {
                    const groupings = [...v.groupings];
                    const name = groupings[index].name || value;
                    groupings[index] = {
                      ...groupings[index],
                      name,
                      entries: Array.from(
                        new Set([...groupings[index].entries, value])
                      ),
                    };
                    return {
                      ...v,
                      groupings,
                    };
                  });
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="subtitle2">MRG Accounts</Typography>
                  {formik.errors["groupings.mappings"] ? (
                    <Alert severity="error">
                      {formik.errors["groupings.mappings"]}
                    </Alert>
                  ) : null}
                  <div>
                    {sortArray(
                      Object.entries({
                        ...scheduleCMappings,
                        ...formik.values.mappings,
                      }).filter(
                        ([k, v]) =>
                          v === "other-expenses" &&
                          !formik.values.groupings.some((grouping) =>
                            grouping.entries.some((entry) => entry === k)
                          )
                      ),
                      ([a], [b]) => a.localeCompare(b)
                    ).map(([key]) => (
                      <DraggableButton key={key} id={key}>
                        {key}
                      </DraggableButton>
                    ))}
                    <DragOverlay />
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.groupingGrid}>
                  <div className={classes.groupingGridInner}>
                    {formik.values.groupings.map((grouping, index) => (
                      <GroupingField
                        id={index}
                        key={index}
                        error={formik.errors[`groupings[${index}].name`]}
                        label={`Grouping ${index + 1}`}
                        value={grouping.name}
                        values={grouping.entries}
                        onDelete={(value) => {
                          formik.setValues((v) => {
                            const groupings = [...v.groupings];
                            groupings[index] = {
                              ...groupings[index],
                              entries: groupings[index].entries.filter(
                                (c) => c !== value
                              ),
                            };

                            if (groupings[index].entries.length <= 0)
                              groupings[index].name = "";

                            return {
                              ...v,
                              groupings,
                            };
                          });
                        }}
                        onChange={(value) => {
                          formik.setValues((v) => {
                            const groupings = [...v.groupings];
                            groupings[index] = {
                              ...groupings[index],
                              name: value,
                            };
                            return {
                              ...v,
                              groupings,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </Grid>
              </DndContext>
            </Grid>
          </DialogContent>
          <DialogActions>
            {generalLedgerAccounts.length > 0 ? (
              <Button
                component="a"
                color="primary"
                disabled={formik.isSubmitting}
                onClick={(e) =>
                  formik.setValues((v) => ({ ...v, step: "mappings" }))
                }
              >
                Go Back
              </Button>
            ) : null}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              I Approve
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              All your income and expenses are summarized on the schedule C. In
              order to do this, expense accounts have to be mapped to lines on
              the schedule C. My Reseller Genie will do this for you
              automatically, but you should review these mappings to make sure
              they are correct. Review the following mappings, make any
              necessary changes, and approve the mappings. You can adjust these
              mappings anytime by clicking the gear icon in the upper right to
              view your profile settings.
            </DialogContentText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>MRG Account</TableCell>
                    <TableCell>Schedule C Line</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedGeneralLedgerAccounts.map((gl) => {
                    const account = gl["General Ledger Account"];
                    const error = formik.errors?.[`mappings.${account}`];
                    return (
                      <TableRow key={gl.id || account}>
                        <TableCell>{gl["General Ledger Account"]}</TableCell>
                        <TableCell>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            disabled={formik.isSubmitting}
                            error={!!error}
                          >
                            <Select
                              fullWidth
                              disabled={formik.isSubmitting}
                              value={
                                formik.values.mappings[
                                  gl["General Ledger Account"]
                                ]
                              }
                              name={`mappings.${gl["General Ledger Account"]}`}
                              onChange={formik.handleChange}
                            >
                              <MenuItem value="" disabled>
                                Select a field
                              </MenuItem>
                              {ScheduleCLineOptions.map((opt) => (
                                <MenuItem value={opt.entry} key={opt.entry}>
                                  {opt.text}
                                </MenuItem>
                              ))}
                            </Select>
                            {error ? (
                              <FormHelperText>{error}</FormHelperText>
                            ) : null}
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              I Approve
            </Button>
          </DialogActions>
        </>
      )}
    </Box>
  );
}

function ScheduleCMappingsDialogContentMemory({
  generalLedgerAccounts: _generalLedgerAccounts,
  open,
  ...props
}: ScheduleCMappingsDialogContentProps & { open: boolean }) {
  const [generalLedgerAccounts, setGeneralLedgerAccounts] = useState(
    _generalLedgerAccounts
  );
  useEffect(() => {
    if (open) setGeneralLedgerAccounts(_generalLedgerAccounts);
  }, [open, _generalLedgerAccounts]);

  return (
    <ScheduleCMappingsDialogContent
      generalLedgerAccounts={generalLedgerAccounts}
      {...props}
    />
  );
}

function ScheduleCMappingsDialog({
  open,
  onClose,
  all,
}: {
  open: boolean;
  onClose: () => void;
  all?: boolean;
}) {
  const allGeneralLedgerAccounts = useSelector(scheduleCAccountsSelector);
  const generalLedgerAccounts = useSelector(
    scheduleCNotMappedGeneralLedgerAccountsSelector
  );
  const accounts = all ? allGeneralLedgerAccounts : generalLedgerAccounts;
  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      maxWidth="md"
      onClose={() => onClose()}
    >
      <DialogTitle>{`Schedule C - ${all ? "" : "New "}Mappings`}</DialogTitle>
      <ScheduleCMappingsDialogContentMemory
        open={open}
        generalLedgerAccounts={accounts}
        onSubmit={() => onClose()}
      />
    </Dialog>
  );
}

export default ScheduleCMappingsDialog;
