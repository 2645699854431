import { getAllSales } from "src/store/sale/selector";
import { getTransactionState } from "src/store/transaction/selector";
import { getInventory } from "src/store/inventory/selector";
import {
  userGetInventoryTypeSelector,
  scheduleCMappingsSelector,
  scheduleCGroupingsSelector,
} from "src/store/system/selector";
import { buildInventoryReport, type InventoryReport } from "../report";
import type { Sale } from "src/interfaces/sale.interface";
import type { Transaction } from "src/interfaces/transaction.interface";
import type { Inventory } from "src/interfaces/inventory.interface";
import type { State, Report2024, ReportResult } from "./types";

// @ts-ignore
import Form2024 from "./pdf/2024.pdf";

function sumExpensesByAccount(
  transactions: Transaction[],
  accounts: string[]
): number {
  let sum = 0;
  for (const transaction of transactions) {
    if (accounts.includes(transaction.account)) sum = sum + transaction.amount;
  }
  return sum;
}

function calculateReport2024(
  mappingsByExpenseType: Record<string, string[]>,
  groupingsByAccountName: Record<string, string>,
  state: State,
  inventoryType: "accrual" | "cash",
  sales: Sale[],
  refunds: Sale[],
  transactions: Transaction[],
  inventory: Inventory[],
  inventoryReport: InventoryReport
): [Report2024, string[]] {
  const errors: string[] = [];
  const report: Report2024 = {
    box1: 0,
    box2: 0,
    box3: 0,
    box4: undefined,
    box5: 0,
    box6: 0,
    box7: 0,
    box8: 0,
    box9: 0,
    box10: 0,
    box11: 0,
    box12: 0,
    box13: 0,
    box14: 0,
    box15: 0,
    box16a: 0,
    box16b: 0,
    box17: 0,
    box18: 0,
    box19: 0,
    box20a: 0,
    box20b: 0,
    box21: 0,
    box22: 0,
    box23: 0,
    box24a: 0,
    box24b: 0,
    box25: 0,
    box26: 0,
    box27a: 0,
    box27b: 0,
    box28: 0,
    box29: 0,
    box30: undefined,
    box30a: undefined,
    box30b: undefined,
    box31: 0,
    box33Cost: undefined,
    box33Lower: undefined,
    box33Other: undefined,
    box34Yes: undefined,
    box34No: undefined,
    box35: undefined,
    box36: undefined,
    box37: undefined,
    box38: undefined,
    box39: undefined,
    box40: undefined,
    box41: undefined,
    box42: undefined,
    box43Month: undefined,
    box43Date: undefined,
    box43Year: undefined,
    box44a: undefined,
    box44b: undefined,
    box44c: undefined,
    box45Yes: undefined,
    box45No: undefined,
    box46Yes: undefined,
    box46No: undefined,
    box47aYes: undefined,
    box47aNo: undefined,
    box47bYes: undefined,
    box47bNo: undefined,
    box48Entry0Name: undefined,
    box48Entry0Amount: undefined,
    box48Entry1Name: undefined,
    box48Entry1Amount: undefined,
    box48Entry2Name: undefined,
    box48Entry2Amount: undefined,
    box48Entry3Name: undefined,
    box48Entry3Amount: undefined,
    box48Entry4Name: undefined,
    box48Entry4Amount: undefined,
    box48Entry5Name: undefined,
    box48Entry5Amount: undefined,
    box48Entry6Name: undefined,
    box48Entry6Amount: undefined,
    box48Entry7Name: undefined,
    box48Entry7Amount: undefined,
    box48Entry8Name: undefined,
    box48Entry8Amount: undefined,
    box48: 0,
  };

  for (const sale of sales) {
    report.box1 += sale.sale_price;
  }

  for (const refund of refunds) {
    report.box2 += Math.abs(refund.sale_price);
  }

  report.box3 = report.box1 - report.box2;

  if (mappingsByExpenseType["advertising"])
    report.box8 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["advertising"]
    );

  if (mappingsByExpenseType["car-and-truck-expenses"])
    report.box9 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["car-and-truck-expenses"]
    );

  if (mappingsByExpenseType["comissions-and-fees"])
    report.box10 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["comissions-and-fees"]
    );

  if (mappingsByExpenseType["contract-labor"])
    report.box11 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["contract-labor"]
    );

  if (mappingsByExpenseType["depletion"])
    report.box12 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["depletion"]
    );

  if (mappingsByExpenseType["depreciation-and-section-179-expense-deduction"])
    report.box13 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["depreciation-and-section-179-expense-deduction"]
    );

  if (mappingsByExpenseType["employee-benefit-programs"])
    report.box14 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["employee-benefit-programs"]
    );

  if (mappingsByExpenseType["insurance"])
    report.box15 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["insurance"]
    );

  if (mappingsByExpenseType["interest-mortage"])
    report.box16a = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["interest-mortage"]
    );

  if (mappingsByExpenseType["interest-other"])
    report.box16b = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["interest-other"]
    );

  if (mappingsByExpenseType["legal-and-professional-services"])
    report.box17 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["legal-and-professional-services"]
    );

  if (mappingsByExpenseType["office-expense"])
    report.box18 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["office-expense"]
    );

  if (mappingsByExpenseType["pension-and-profit-sharing-plans"])
    report.box19 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["pension-and-profit-sharing-plans"]
    );

  if (mappingsByExpenseType["rent-vehicles-machinery-and-equipment"])
    report.box20a = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["rent-vehicles-machinery-and-equipment"]
    );

  if (mappingsByExpenseType["rent-other-business-property"])
    report.box20b = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["rent-other-business-property"]
    );

  if (mappingsByExpenseType["repairs-and-maintenance"])
    report.box21 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["repairs-and-maintenance"]
    );

  if (mappingsByExpenseType["supplies"])
    report.box22 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["supplies"]
    );

  if (mappingsByExpenseType["taxes-and-licenses"])
    report.box23 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["taxes-and-licenses"]
    );

  if (mappingsByExpenseType["travel-and-meals-travel"])
    report.box24a = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["travel-and-meals-travel"]
    );

  if (mappingsByExpenseType["travel-and-meals-deductible-meals"])
    report.box24b = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["travel-and-meals-deductible-meals"]
    );

  if (mappingsByExpenseType["utilities"])
    report.box25 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["utilities"]
    );

  if (mappingsByExpenseType["wages"])
    report.box26 = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["wages"]
    );

  if (mappingsByExpenseType["other-expenses"]) {
    let sum = 0;
    const totalByAccount: Record<string, number> = {};
    for (const expense of transactions) {
      if (!mappingsByExpenseType["other-expenses"].includes(expense.account))
        continue;
      sum += expense.amount;
      const name = groupingsByAccountName[expense.account] || expense.account;
      if (!totalByAccount[name]) totalByAccount[name] = 0;
      totalByAccount[name] += expense.amount;
    }
    report.box27a = report.box48 = sum;
    const accounts = Object.keys(totalByAccount);
    accounts.sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < accounts.length && i < 9; i++) {
      const name = accounts[i];
      report[`box48Entry${i}Name`] = name;
      report[`box48Entry${i}Amount`] = totalByAccount[name];
    }
  }

  if (mappingsByExpenseType["energy-efficient-commercial-bldgs-deduction"])
    report.box27b = sumExpensesByAccount(
      transactions,
      mappingsByExpenseType["energy-efficient-commercial-bldgs-deduction"]
    );

  report.box28 =
    report.box8 +
    report.box9 +
    report.box10 +
    report.box11 +
    report.box12 +
    report.box13 +
    report.box14 +
    report.box15 +
    report.box16a +
    report.box16b +
    report.box17 +
    report.box18 +
    report.box19 +
    report.box20a +
    report.box20b +
    report.box21 +
    report.box22 +
    report.box23 +
    report.box24a +
    report.box24b +
    report.box25 +
    report.box26 +
    report.box27a +
    report.box27b;

  if (state.deduction.open) {
    report.box30 = state.deduction.amount || 0;
    report.box30a = state.deduction.home || 0;
    report.box30b = state.deduction.business || 0;
  }

  if (inventoryType === "accrual") {
    report.box33Cost = true;
    report.box34No = true;
    report.box35 = inventoryReport.data.yearStartBalance;
    report.box36 = inventoryReport.data.purchasesDuringYear;
    report.box37 = 0;
    report.box38 = 0;
    report.box39 = 0;
    report.box40 =
      (report.box35 || 0) +
      (report.box36 || 0) +
      (report.box37 || 0) +
      (report.box38 || 0) +
      (report.box39 || 0);

    report.box41 = inventoryReport.data.yearEndBalance;
    report.box42 = inventoryReport.data.inventoryCost;

    if (Math.round(report.box42) !== Math.round(report.box40 - report.box41))
      errors.push(
        "There is a problem with some of the inventory data you have entered that is causing inaccurate reporting.(Box 35, 36 and 41)"
      );

    report.box4 = report.box42;
  }

  report.box5 = report.box3 - (report.box4 || 0);
  report.box7 = report.box5 + report.box6;
  report.box29 = report.box7 - report.box28;
  report.box31 = report.box29 - (report.box30 || 0);

  if (state.vehicle.open) {
    if (state.vehicle.when) {
      report.box43Month = `${state.vehicle.when.getMonth() + 1}`.padStart(
        2,
        "0"
      );
      report.box43Date = `${state.vehicle.when.getDate()}`.padStart(2, "0");
      report.box43Year = `${state.vehicle.when.getFullYear()}`;
    }

    report.box44a = state.vehicle.business || 0;
    report.box44b = state.vehicle.commuting || 0;
    report.box44c = state.vehicle.other || 0;
    if (state.vehicle.personalUseOffDuty === "yes") report.box45Yes = true;
    else if (state.vehicle.personalUseOffDuty === "no") report.box45No = true;
    if (state.vehicle.anotherVehiclePersonalUse === "yes")
      report.box46Yes = true;
    else if (state.vehicle.anotherVehiclePersonalUse === "no")
      report.box46No = false;

    if (state.vehicle.evidence === "yes") report.box47aYes = true;
    else if (state.vehicle.evidence === "no") report.box47aNo = false;

    if (state.vehicle.evidence === "yes") {
      if (state.vehicle.writtenEvidence === "yes") report.box47bYes = true;
      else if (state.vehicle.writtenEvidence === "no") report.box47bNo = false;
    }
  }

  return [report, errors];
}

const Fields2024: Record<keyof Report2024, string> = {
  box1: "topmostSubform[0].Page1[0].f1_10[0]",
  box2: "topmostSubform[0].Page1[0].f1_11[0]",
  box3: "topmostSubform[0].Page1[0].f1_12[0]",
  box4: "topmostSubform[0].Page1[0].f1_13[0]",
  box5: "topmostSubform[0].Page1[0].f1_14[0]",
  box6: "topmostSubform[0].Page1[0].f1_15[0]",
  box7: "topmostSubform[0].Page1[0].f1_16[0]",
  box8: "topmostSubform[0].Page1[0].Lines8-17[0].f1_17[0]",
  box9: "topmostSubform[0].Page1[0].Lines8-17[0].f1_18[0]",
  box10: "topmostSubform[0].Page1[0].Lines8-17[0].f1_19[0]",
  box11: "topmostSubform[0].Page1[0].Lines8-17[0].f1_20[0]",
  box12: "topmostSubform[0].Page1[0].Lines8-17[0].f1_21[0]",
  box13: "topmostSubform[0].Page1[0].Lines8-17[0].f1_22[0]",
  box14: "topmostSubform[0].Page1[0].Lines8-17[0].f1_23[0]",
  box15: "topmostSubform[0].Page1[0].Lines8-17[0].f1_24[0]",
  box16a: "topmostSubform[0].Page1[0].Lines8-17[0].f1_25[0]",
  box16b: "topmostSubform[0].Page1[0].Lines8-17[0].f1_26[0]",
  box17: "topmostSubform[0].Page1[0].Lines8-17[0].f1_27[0]",
  box18: "topmostSubform[0].Page1[0].Lines18-27[0].f1_28[0]",
  box19: "topmostSubform[0].Page1[0].Lines18-27[0].f1_29[0]",
  box20a: "topmostSubform[0].Page1[0].Lines18-27[0].f1_30[0]",
  box20b: "topmostSubform[0].Page1[0].Lines18-27[0].f1_31[0]",
  box21: "topmostSubform[0].Page1[0].Lines18-27[0].f1_32[0]",
  box22: "topmostSubform[0].Page1[0].Lines18-27[0].f1_33[0]",
  box23: "topmostSubform[0].Page1[0].Lines18-27[0].f1_34[0]",
  box24a: "topmostSubform[0].Page1[0].Lines18-27[0].f1_35[0]",
  box24b: "topmostSubform[0].Page1[0].Lines18-27[0].f1_36[0]",
  box25: "topmostSubform[0].Page1[0].Lines18-27[0].f1_37[0]",
  box26: "topmostSubform[0].Page1[0].Lines18-27[0].f1_38[0]",
  box27a: "topmostSubform[0].Page1[0].Lines18-27[0].f1_39[0]",
  box27b: "topmostSubform[0].Page1[0].Lines18-27[0].f1_40[0]",
  box28: "topmostSubform[0].Page1[0].f1_41[0]",
  box29: "topmostSubform[0].Page1[0].f1_42[0]",
  box30: "topmostSubform[0].Page1[0].f1_45[0]",
  box30a: "topmostSubform[0].Page1[0].Line30_ReadOrder[0].f1_43[0]",
  box30b: "topmostSubform[0].Page1[0].Line30_ReadOrder[0].f1_44[0]",
  box31: "topmostSubform[0].Page1[0].f1_46[0]",
  box33Cost: "topmostSubform[0].Page2[0].c2_1[0]",
  box33Lower: "topmostSubform[0].Page2[0].c2_2[0]",
  box33Other: "topmostSubform[0].Page2[0].c2_3[0]",
  box34Yes: "topmostSubform[0].Page2[0].c2_4[0]",
  box34No: "topmostSubform[0].Page2[0].c2_4[1]",
  box35: "topmostSubform[0].Page2[0].f2_1[0]",
  box36: "topmostSubform[0].Page2[0].f2_2[0]",
  box37: "topmostSubform[0].Page2[0].f2_3[0]",
  box38: "topmostSubform[0].Page2[0].f2_4[0]",
  box39: "topmostSubform[0].Page2[0].f2_5[0]",
  box40: "topmostSubform[0].Page2[0].f2_6[0]",
  box41: "topmostSubform[0].Page2[0].f2_7[0]",
  box42: "topmostSubform[0].Page2[0].f2_8[0]",
  box43Month: "topmostSubform[0].Page2[0].f2_9[0]",
  box43Date: "topmostSubform[0].Page2[0].f2_10[0]",
  box43Year: "topmostSubform[0].Page2[0].f2_11[0]",
  box44a: "topmostSubform[0].Page2[0].f2_12[0]",
  box44b: "topmostSubform[0].Page2[0].f2_13[0]",
  box44c: "topmostSubform[0].Page2[0].f2_14[0]",
  box45Yes: "topmostSubform[0].Page2[0].c2_5[0]",
  box45No: "topmostSubform[0].Page2[0].c2_5[1]",
  box46Yes: "topmostSubform[0].Page2[0].c2_6[0]",
  box46No: "topmostSubform[0].Page2[0].c2_6[1]",
  box47aYes: "topmostSubform[0].Page2[0].c2_7[0]",
  box47aNo: "topmostSubform[0].Page2[0].c2_7[1]",
  box47bYes: "topmostSubform[0].Page2[0].c2_8[0]",
  box47bNo: "topmostSubform[0].Page2[0].c2_8[1]",
  box48Entry0Name: "topmostSubform[0].Page2[0].PartVTable[0].Item1[0].f2_15[0]",
  box48Entry0Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item1[0].f2_16[0]",
  box48Entry1Name: "topmostSubform[0].Page2[0].PartVTable[0].Item2[0].f2_17[0]",
  box48Entry1Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item2[0].f2_18[0]",
  box48Entry2Name: "topmostSubform[0].Page2[0].PartVTable[0].Item3[0].f2_19[0]",
  box48Entry2Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item3[0].f2_20[0]",
  box48Entry3Name: "topmostSubform[0].Page2[0].PartVTable[0].Item4[0].f2_21[0]",
  box48Entry3Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item4[0].f2_22[0]",
  box48Entry4Name: "topmostSubform[0].Page2[0].PartVTable[0].Item5[0].f2_23[0]",
  box48Entry4Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item5[0].f2_24[0]",
  box48Entry5Name: "topmostSubform[0].Page2[0].PartVTable[0].Item6[0].f2_25[0]",
  box48Entry5Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item6[0].f2_26[0]",
  box48Entry6Name: "topmostSubform[0].Page2[0].PartVTable[0].Item7[0].f2_27[0]",
  box48Entry6Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item7[0].f2_28[0]",
  box48Entry7Name: "topmostSubform[0].Page2[0].PartVTable[0].Item8[0].f2_29[0]",
  box48Entry7Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item8[0].f2_30[0]",
  box48Entry8Name: "topmostSubform[0].Page2[0].PartVTable[0].Item9[0].f2_31[0]",
  box48Entry8Amount:
    "topmostSubform[0].Page2[0].PartVTable[0].Item9[0].f2_32[0]",
  box48: "topmostSubform[0].Page2[0].f2_33[0]",
};

async function render2024(report: Report2024): Promise<string> {
  const PDFLib = await import("pdf-lib").then((m) => m.default || m);
  const { PDFDocument, PDFString, PDFName } = PDFLib;
  const url = Form2024 as string;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const form = pdfDoc.getForm();
  const allfields = form.acroForm.getAllFields();

  const fillField = (selector: string, value: string | number | boolean) => {
    for (const field of allfields) {
      const f = field[0];
      const name = f.getFullyQualifiedName();
      if (name === selector) {
        switch (typeof value) {
          case "number":
            (f as any).setValue(PDFString.of(`${Math.round(value)}`));
            break;
          case "string":
            (f as any).setValue(PDFString.of(value));
            break;
          case "boolean":
            (f as any).setValue(
              value
                ? (f as any).getOnValue() ?? PDFName.of("Yes")
                : PDFName.of("Off")
            );
            break;
        }
        return true;
      }
    }
    return false;
  };

  for (const [key, selector] of Object.entries(Fields2024)) {
    const value = report[key];
    if (value !== undefined) {
      fillField(selector, value);
    }
  }

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return pdfDataUri;
}

export async function renderReport(
  store,
  _year: number | string,
  state: State
): Promise<ReportResult> {
  const year = parseInt(`${_year}`);
  const startDate = new Date(`${year}-01-01T00:00:00.000`);
  const endDate = new Date(`${year}-12-31T23:59:59.999`);
  const storeState = store.getState();
  const mappings = scheduleCMappingsSelector(storeState);
  const groupings = scheduleCGroupingsSelector(storeState);
  const allSales = getAllSales(storeState);
  const allInventory = getInventory(storeState);
  const sales: Sale[] = [];
  const refunds: Sale[] = [];
  const transactions: Transaction[] = [];
  const inventory: Inventory[] = [];
  const inventoryType = userGetInventoryTypeSelector(storeState);
  const errors: ReportResult["errors"] = [];

  if (year !== 2024) {
    errors.push({
      severity: "warning",
      message: `My Reseller Genie does not have the ${year} form preloaded. Your Schedule C for ${year} will be generated on the 2024 form.`,
    });
  }

  let salesShippingCosts = 0;
  let salesTransactionFees = 0;

  for (const sale of allSales) {
    const isRefund = sale.is_return || sale.original_id;
    if (isRefund) {
      const date = sale.return_date;
      if (!date || date < startDate || endDate < date) continue;
      refunds.push(sale);
      salesShippingCosts +=
        (sale.shipping_cost || 0) + (sale.extra_shipping_cost || 0);
      salesTransactionFees +=
        (sale.transaction_fees || 0) + (sale.extra_transaction_fees || 0);
    } else {
      const date = sale.sale_date;
      if (!date || date < startDate || endDate < date) continue;
      sales.push(sale);
      salesShippingCosts +=
        (sale.shipping_cost || 0) + (sale.extra_shipping_cost || 0);
      salesTransactionFees +=
        (sale.transaction_fees || 0) + (sale.extra_transaction_fees || 0);
    }
  }

  transactions.push({
    id: "sales-shipping-costs",
    account: "Sales - Shipping Costs",
    description: "Sales - Shipping Costs",
    amount: salesShippingCosts,
  });

  transactions.push({
    id: "sales-transaction-fees",
    account: "Sales - Transaction Fees",
    description: "Sales - Transaction Fees",
    amount: salesTransactionFees,
  });

  for (const transaction of getTransactionState(storeState).items) {
    const date = transaction.date;
    if (!date || date < startDate || endDate < date) continue;
    transactions.push(transaction);
  }

  for (const item of allInventory) {
    const date = item.list_date;
    if (!date || date < startDate || endDate < date) continue;
    inventory.push(item);
  }

  const mappingsByExpenseType = Object.entries(mappings).reduce(
    (mbet, [key, value]) => {
      if (!mbet[value]) mbet[value] = [];
      mbet[value].push(key);
      return mbet;
    },
    {} as Record<string, string[]>
  );

  const inventoryReport = buildInventoryReport(
    "inventory",
    { year },
    allSales,
    allInventory
  );
  const [data, reportErrors] = calculateReport2024(
    mappingsByExpenseType,
    groupings,
    state,
    inventoryType,
    sales,
    refunds,
    transactions,
    inventory,
    inventoryReport
  );

  return {
    url: await render2024(data),
    errors: [
      ...errors,
      ...reportErrors.map((message) => ({
        message,
        severity: "warning" as const,
      })),
    ],
  };
}
