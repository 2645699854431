import React, {
  useState,
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import NumberField from "src/components/NumberField";

import type { State, YesNo } from "./types";

interface InputsDialogProps {
  onChange: Dispatch<SetStateAction<State>>;
  onClose: DialogProps["onClose"];
  onRunReport: () => void;
  state: State;
  year: number;
}

const useYesNoRowStyles = makeStyles((theme) => ({
  radioGroup: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  formControlLabel: {
    margin: 0,
  },
}));

interface YesNoRowProps {
  onChange: RadioGroupProps["onChange"];
  value: YesNo;
  error?: string;
}

function YesNoRow({
  children,
  value,
  onChange,
  error,
}: PropsWithChildren<YesNoRowProps>) {
  const classes = useYesNoRowStyles();
  return (
    <tr>
      <td>
        <Typography color={error ? "error" : undefined}>{children}</Typography>
      </td>
      <td>
        <RadioGroup row className={classes.radioGroup} onChange={onChange}>
          <FormControlLabel
            className={classes.formControlLabel}
            value="yes"
            control={<Radio color="primary" />}
            label="Yes"
            labelPlacement="top"
          />
          <FormControlLabel
            className={classes.formControlLabel}
            value="no"
            control={<Radio color="primary" />}
            label="No"
            labelPlacement="top"
          />
        </RadioGroup>
      </td>
    </tr>
  );
}
const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  row: {
    display: "table-row",
  },
  cell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  padleft: {
    paddingLeft: theme.spacing(3),
  },
  textfield: {
    maxWidth: "12em",
  },
  right: {
    textAlign: "right",
  },
}));

function InputsDialogContent({
  onChange,
  onRunReport,
  state,
  year,
}: Omit<InputsDialogProps, "onClose">) {
  const classes = useStyles();
  const [errors, setErrors] = useState<Record<string, string>>({});

  function handleSubmit() {
    const errors: Record<string, string> = {};
    if (state.deduction.open) {
      if (state.deduction.amount !== 0 && !state.deduction.amount)
        errors["deductionAmount"] = "Required field";
      if (state.deduction.home !== 0 && !state.deduction.home)
        errors["deductionHome"] = "Required field";
      if (state.deduction.business !== 0 && !state.deduction.business)
        errors["deductionBusiness"] = "Required field";
      if (
        !errors["deductionHome"] &&
        !errors["deductionBusiness"] &&
        state.deduction.business > state.deduction.home
      ) {
        errors["deductionHome"] = errors["deductionBusiness"] =
          "'Sq ft used for business:' must be lower of equal to 'Total sq ft of home:'";
      }
    }

    if (state.vehicle.open) {
      if (!state.vehicle.when) errors["vehicleWhen"] = "Required field";
      if (state.vehicle.business !== 0 && !state.vehicle.business)
        errors["vehicleBusiness"] = "Required field";
      if (state.vehicle.commuting !== 0 && !state.vehicle.commuting)
        errors["vehicleCommuting"] = "Required field";
      if (state.vehicle.other !== 0 && !state.vehicle.other)
        errors["vehicleOther"] = "Required field";
      if (!state.vehicle.personalUseOffDuty)
        errors["vehiclePersonalUseOffDuty"] = "Required field";
      if (!state.vehicle.anotherVehiclePersonalUse)
        errors["vehicleAnotherVehiclePersonalUse"] = "Required field";
      if (!state.vehicle.evidence) {
        errors["vehicleEvidence"] = "Required field";
      } else if (state.vehicle.evidence === "yes") {
        if (!state.vehicle.writtenEvidence)
          errors["vehicleWrittenEvidence"] = "Required field";
      }
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    onRunReport();
  }

  return (
    <>
      <DialogContent>
        <DialogContentText>
          Some information on the schedule C is not available in your books, so
          you can enter this information here:
        </DialogContentText>
        <Accordion
          expanded={state.deduction.open}
          onChange={(ev, open) =>
            onChange((v) => ({ ...v, deduction: { ...v.deduction, open } }))
          }
        >
          <AccordionSummary
            expandIcon={state.deduction.open ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography>Click here to add a home office deduction</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      color={!!errors.deductionAmount ? "error" : undefined}
                    >
                      Deduction amount (line 30)
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.deductionAmount}
                      helperText={errors.deductionAmount}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.deduction.amount}
                      onChange={(ev) => {
                        const amount = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          deduction: { ...v.deduction, amount },
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Typography
                      color={!!errors.deductionHome ? "error" : undefined}
                    >
                      Simplified method filers only:
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.padleft}>
                    <Typography
                      color={!!errors.deductionHome ? "error" : undefined}
                    >
                      Total sq ft of home:
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.deductionHome}
                      helperText={errors.deductionHome}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.deduction.home}
                      onChange={(ev) => {
                        const home = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          deduction: { ...v.deduction, home },
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">ft²</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={classes.padleft}>
                    <Typography
                      color={!!errors.deductionBusiness ? "error" : undefined}
                    >
                      Sq ft used for business:
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.deductionBusiness}
                      helperText={errors.deductionBusiness}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.deduction.business}
                      onChange={(ev) => {
                        const business = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          deduction: { ...v.deduction, business },
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">ft²</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={state.vehicle.open}
          onChange={(ev, open) =>
            onChange((v) => ({ ...v, vehicle: { ...v.vehicle, open } }))
          }
        >
          <AccordionSummary
            expandIcon={state.vehicle.open ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography>
              Click here if you are claiming a deduction for business use of a
              vehicle
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      color={errors.vehicleWhen ? "error" : undefined}
                    >
                      When did you place your vehicle in service for business
                      purposes? (month/day/year)
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.textfield}
                        fullWidth
                        disableToolbar
                        format="MM/dd/yyyy"
                        margin="dense"
                        inputVariant="outlined"
                        autoOk={true}
                        error={!!errors.vehicleWhen}
                        helperText={errors.vehicleWhen}
                        value={state.vehicle.when || null}
                        onChange={(e) => {
                          const when = e ? new Date(e) : "";
                          onChange((v) => ({
                            ...v,
                            vehicle: { ...v.vehicle, when },
                          }));
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Typography>
                      Total Number of Miles Driven for {year}:
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.padleft}>
                    <Typography
                      color={errors.vehicleBusiness ? "error" : undefined}
                    >
                      Business:
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.vehicleBusiness}
                      helperText={errors.vehicleBusiness}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.vehicle.business}
                      onChange={(ev) => {
                        const business = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          vehicle: { ...v.vehicle, business },
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mi</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={classes.padleft}>
                    <Typography
                      color={errors.vehicleCommuting ? "error" : undefined}
                    >
                      Commuting:
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.vehicleCommuting}
                      helperText={errors.vehicleCommuting}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.vehicle.commuting}
                      onChange={(ev) => {
                        const commuting = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          vehicle: { ...v.vehicle, commuting },
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mi</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={classes.padleft}>
                    <Typography
                      color={errors.vehicleOther ? "error" : undefined}
                    >
                      Other:
                    </Typography>
                  </td>
                  <td className={classes.right}>
                    <NumberField
                      error={!!errors.vehicleOther}
                      helperText={errors.vehicleOther}
                      fixed={2}
                      className={classes.textfield}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={state.vehicle.other}
                      onChange={(ev) => {
                        const other = ev.target.value ?? "";
                        onChange((v) => ({
                          ...v,
                          vehicle: { ...v.vehicle, other },
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mi</InputAdornment>
                        ),
                      }}
                    />
                  </td>
                </tr>
                <YesNoRow
                  error={errors.vehiclePersonalUseOffDuty}
                  value={state.vehicle.personalUseOffDuty}
                  onChange={(ev) => {
                    const personalUseOffDuty = ev.target.value as YesNo;
                    onChange((v) => ({
                      ...v,
                      vehicle: { ...v.vehicle, personalUseOffDuty },
                    }));
                  }}
                >
                  Was your vehicle available for personal use during off-duty
                  hours?
                </YesNoRow>
                <YesNoRow
                  error={errors.vehicleAnotherVehiclePersonalUse}
                  value={state.vehicle.anotherVehiclePersonalUse}
                  onChange={(ev) => {
                    const anotherVehiclePersonalUse = ev.target.value as YesNo;
                    onChange((v) => ({
                      ...v,
                      vehicle: { ...v.vehicle, anotherVehiclePersonalUse },
                    }));
                  }}
                >
                  Do you (or your spouse) have another vehicle available for
                  personal use?
                </YesNoRow>
                <YesNoRow
                  error={errors.vehicleEvidence}
                  value={state.vehicle.evidence}
                  onChange={(ev) => {
                    const evidence = ev.target.value as YesNo;
                    onChange((v) => ({
                      ...v,
                      vehicle: { ...v.vehicle, evidence },
                    }));
                  }}
                >
                  Do you have evidence to support your deduction?
                </YesNoRow>
                {state.vehicle.evidence === "yes" ? (
                  <YesNoRow
                    error={errors.vehicleWrittenEvidence}
                    value={state.vehicle.writtenEvidence}
                    onChange={(ev) => {
                      const writtenEvidence = ev.target.value as YesNo;
                      onChange((v) => ({
                        ...v,
                        vehicle: { ...v.vehicle, writtenEvidence },
                      }));
                    }}
                  >
                    If “Yes”, is the evidence written?
                  </YesNoRow>
                ) : null}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Run Report
        </Button>
      </DialogActions>
    </>
  );
}

function InputsDialog({
  onChange,
  onClose,
  onRunReport,
  state,
  year,
}: InputsDialogProps) {
  return (
    <Dialog
      open={state.inputsDialogOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Schedule C - Inputs</DialogTitle>
      <InputsDialogContent
        year={year}
        state={state}
        onChange={onChange}
        onRunReport={onRunReport}
      />
    </Dialog>
  );
}

export default InputsDialog;
