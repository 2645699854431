import React, {
  useState,
  type Dispatch,
  type SetStateAction,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import capitalize from "lodash/capitalize";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import DeleteIcon from "@material-ui/icons/Delete";
import SyncIcon from "@material-ui/icons/Sync";

import {
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import { getPlaidIntegrations } from "src/store/plaidIntegration/selector";
import { USER_PLAN as UserPlan } from "src/enums/common.enum";
import {
  deleteIntegration as plaidDeleteIntegration,
  syncAccount as plaidSyncAccount,
} from "src/apiService/modules/plaid";
import {
  eBayIntegrationSyncChange,
  ebaySyncApi,
} from "src/apiService/modules/ebay";
import {
  poshmarkIntegrationCreate,
  poshmarkIntegrationSyncChange,
  poshmarkIntegrationDelete,
} from "src/apiService/modules/poshmark";
import { setActiveDialog } from "src/store/adminHtml/actions";
import PlaidAddIntegrationDialog from "src/components/PlaidAddIntegrationDialog";
import { StatefulLoadableIconButton } from "src/components/LoadableIconButton";
import {
  getEBayIntegrations,
  getPoshmarkIntegrations,
} from "src/store/plaidIntegration/selector";
import DeleteIntegrationDialog from "src/pages/GetStarted/DeleteIntegrationDialog";
import AddNewIntegrationDialog from "src/pages/GetStarted/AddNewIntegrationDialog";
import ImportDialog from "src/pages/GetStarted/ImportDialog";
import AddPoshmarkExtensionDialog from "src/components/AddPoshmarkExtensionDialog";
import PoshmarkConfirmDialog from "src/components/PoshmarkConfirmDialog";

import { MissingExtensionError } from "src/utils/extension";
import { getPoshmarkUser } from "src/utils/extension/poshmark";
import {
  poshmarkSyncWithCheck,
  poshmarkSyncInventory,
  poshmarkSyncSales,
} from "src/store/integrationSync/actions/poshmark";
import { IsRunningThreshold as PoshmarkIsRunningThreshold } from "src/store/integrationSync/actions/utils";
import { usePreventImportPoshmark } from "src/pages/GetStarted/usePreventImportPoshmark";

import MonthYear from "src/interfaces/monthYear.interface";
import type { PoshmarkIntegration } from "src/interfaces/plaidIntegration.interface";
import type { DialogState } from "./types";
import PlatformIntegrationsTableRow from "./PlatformIntegrationsTableRow";
import useIsRunning from "./useIsRunning";
import MercariRows from "./MercariRows";
import { MercariNotice } from "./MercariRows/Row";
import EtsyRows from "./EtsyRows";
import AmazonRows from "./AmazonRows";

// vim-highligh-fix = }

function PoshmarkIntegrationTableRow({
  integration,
  setDialog,
  hideInventory,
}: {
  integration: PoshmarkIntegration;
  setDialog: Dispatch<SetStateAction<DialogState>>;
  hideInventory: boolean;
}) {
  const dispatch = useDispatch();
  const isRunning = useIsRunning(
    integration.salesIsRunning,
    integration.inventoryIsRunning,
    PoshmarkIsRunningThreshold
  );
  const AddComponent = useMemo(() => {
    return () => (
      <Button
        color="primary"
        variant="contained"
        onClick={() => dispatch(setActiveDialog("poshmark_update_shipping"))}
      >
        Update Shipping
      </Button>
    );
  }, [dispatch]);

  return (
    <PlatformIntegrationsTableRow
      key={integration.id}
      name="Poshmark"
      username={integration.username}
      platform="Poshmark"
      isRunning={isRunning}
      sync={integration.sync}
      inventory={integration.sync && integration.inventory !== false}
      onPullDataNow={async () => {
        await dispatch(poshmarkSyncWithCheck(integration.id, true));
      }}
      onImport={() => {
        setDialog({
          type: "import",
          props: {
            integrationId: integration.id,
            fetchListings: (integrationId) =>
              dispatch(poshmarkSyncInventory(integrationId)),
            fetchTransactions: async (
              integrationId: string,
              start: MonthYear,
              end: MonthYear
            ) => dispatch(poshmarkSyncSales(integrationId, { start, end })),
            providerName: "Poshmark",
            usePreventImport: usePreventImportPoshmark,
          },
        });
      }}
      onSyncChanged={async (sync, inventory) => {
        await poshmarkIntegrationSyncChange(integration.id, sync, inventory);
        if ((inventory === undefined || inventory) && sync)
          await dispatch(poshmarkSyncWithCheck(integration.id, true));
      }}
      hideInventory={hideInventory}
      onDelete={() =>
        setDialog({
          type: "delete",
          props: {
            integration,
            deleteIntegration: poshmarkIntegrationDelete,
          },
        })
      }
      AddComponent={AddComponent}
    />
  );
}

function PlatformIntegrationsTable() {
  const hideInventory = useSelector(
    (s: any) => userGetInventoryTypeSelector(s) === "cash"
  );
  const ebayIntegrations = useSelector(getEBayIntegrations);
  const poshmarkIntegrations = useSelector(getPoshmarkIntegrations);
  const [dialog, setDialog] = useState<DialogState>({});

  const handlePoshmarkAdd = async () => {
    try {
      const data = await getPoshmarkUser();
      setDialog({
        type: "poshmark-confirm",
        props: {
          username: data.username,
          onConfirm: async () => {
            if (data?.jwt)
              await poshmarkIntegrationCreate({
                jwt: data.jwt,
                username: data.username,
              });
            setDialog({});
          },
        },
      });
    } catch (e) {
      if (e instanceof MissingExtensionError) {
        setDialog({ type: "poshmark-add-extension" });
        return;
      }
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      <AddNewIntegrationDialog
        open={dialog.type === "ebay-add"}
        onClose={() => setDialog({})}
      />
      <DeleteIntegrationDialog
        {...(dialog.type === "delete" ? dialog.props : {})}
        onClose={() => setDialog({})}
      />
      <ImportDialog
        {...(dialog.type === "import" ? dialog.props : {})}
        onClose={() => setDialog({})}
      />
      <AddPoshmarkExtensionDialog
        open={
          dialog.type === "poshmark-add-extension" ||
          dialog.type === "mercari-add-extension"
        }
        {...dialog.props}
        onClose={() => setDialog({})}
      />
      <PoshmarkConfirmDialog
        {...(dialog.type === "poshmark-confirm" ||
        dialog.type === "mercari-confirm"
          ? dialog.props
          : {})}
        onCancel={() => setDialog({})}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell align="center">Import Past Data</TableCell>
            <TableCell align="center">
              Daily Integration - Sales and Fees
            </TableCell>
            {hideInventory ? null : (
              <TableCell align="center">
                Daily Integration - Inventory
              </TableCell>
            )}
            <TableCell align="center">Pull Data Now</TableCell>
            <TableCell align="center">Add</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ebayIntegrations.length === 0 ? (
            <PlatformIntegrationsTableRow
              name="eBay"
              platform="eBay"
              isRunning={false}
              sync={false}
              inventory={false}
              onImport={() => {
                setDialog({ type: "ebay-add" });
              }}
              hideInventory={hideInventory}
              onSyncChanged={() => {
                setDialog({ type: "ebay-add" });
              }}
              onPullDataNow={async () => {
                setDialog({ type: "ebay-add" });
              }}
              onAdd={() => {
                setDialog({ type: "ebay-add" });
              }}
            />
          ) : (
            ebayIntegrations.map((integration) => {
              const isRunning = !!(
                integration.salesIsRunning || integration.inventoryIsRunning
              );

              return (
                <PlatformIntegrationsTableRow
                  key={integration.id}
                  name={integration.name || "eBay"}
                  username={integration.username}
                  platform="eBay"
                  isRunning={isRunning}
                  sync={integration.sync}
                  inventory={
                    integration.sync && integration.inventory !== false
                  }
                  onImport={() => {
                    setDialog({
                      type: "import",
                      props: { integrationId: integration.id },
                    });
                  }}
                  onSyncChanged={async (sync, inventory) => {
                    await eBayIntegrationSyncChange(
                      integration.id,
                      sync,
                      inventory
                    );
                    if ((inventory === undefined || inventory) && sync)
                      ebaySyncApi(integration.id);
                  }}
                  hideInventory={hideInventory}
                  onPullDataNow={async () => {
                    await ebaySyncApi(integration.id);
                    toast.success(
                      "Request received. Your imports are processing."
                    );
                  }}
                  onAdd={() => setDialog({ type: "ebay-add" })}
                  onDelete={() =>
                    setDialog({
                      type: "delete",
                      props: { integration },
                    })
                  }
                />
              );
            })
          )}
          {poshmarkIntegrations.length === 0 ? (
            <PlatformIntegrationsTableRow
              name="Poshmark"
              platform="Poshmark"
              isRunning={false}
              sync={false}
              inventory={false}
              onImport={handlePoshmarkAdd}
              hideInventory={hideInventory}
              onPullDataNow={async () => handlePoshmarkAdd()}
              onSyncChanged={handlePoshmarkAdd}
              onAdd={handlePoshmarkAdd}
            />
          ) : (
            poshmarkIntegrations.map((integration) => (
              <PoshmarkIntegrationTableRow
                key={integration.id}
                integration={integration}
                hideInventory={hideInventory}
                setDialog={setDialog}
              />
            ))
          )}
          <MercariRows hideInventory={hideInventory} setDialog={setDialog} />
          <EtsyRows hideInventory={hideInventory} setDialog={setDialog} />
          <AmazonRows hideInventory={hideInventory} setDialog={setDialog} />
        </TableBody>
      </Table>
    </>
  );
}

function BankIntegrationsTable() {
  const dispatch = useDispatch();
  const plan = useSelector(userPlanSelector) || UserPlan.BASIC;
  const isBasic = plan === UserPlan.BASIC;
  const plaidIntegrations = useSelector(getPlaidIntegrations);
  const [open, setOpen] = useState(false);
  const openAddCashAccount = () => {
    dispatch(setActiveDialog("add_cash_account"));
  };

  return (
    <>
      <PlaidAddIntegrationDialog open={open} onClose={() => setOpen(false)} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="center">Pull Data Now</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isBasic ? (
            <TableRow>
              <TableCell colSpan={4} align="center">
                As a Basic user, you can’t connect a bank or credit card to your
                account. If you’d like this functionality, you can upgrade by
                clicking the “Upgrade” button in the bottom left of the screen.
              </TableCell>
            </TableRow>
          ) : plaidIntegrations.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No bank integrations.
              </TableCell>
            </TableRow>
          ) : (
            plaidIntegrations.map((plaidIntegration) => {
              return (
                <TableRow key={`plaid-${plaidIntegration.id}`}>
                  <TableCell>{plaidIntegration.account_name}</TableCell>
                  <TableCell>{capitalize(plaidIntegration.type)}</TableCell>
                  <TableCell align="center">
                    <StatefulLoadableIconButton
                      onClick={async () => {
                        const results = await plaidSyncAccount(
                          plaidIntegration.id
                        );
                        if (results.success) {
                          toast.success(
                            "Request received. Your imports are processing."
                          );
                        } else {
                          toast.error("Account Syncing Errored.");
                        }
                      }}
                      color="primary"
                    >
                      <SyncIcon />
                    </StatefulLoadableIconButton>
                  </TableCell>
                  <TableCell align="center">
                    <StatefulLoadableIconButton
                      color="secondary"
                      onClick={async () =>
                        await plaidDeleteIntegration(plaidIntegration.id)
                      }
                    >
                      <DeleteIcon />
                    </StatefulLoadableIconButton>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {isBasic ? null : (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add Expense Integration
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "8px" }}
            onClick={openAddCashAccount}
          >
            Add Cash Integration
          </Button>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2, 2),
  },
  separator: {
    marginTop: theme.spacing(4),
  },
}));

function Content() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">Platform Integrations</Typography>
      <PlatformIntegrationsTable />
      <Box mt={2}>
        <Typography display="block" variant="caption">
          * {MercariNotice}
        </Typography>
      </Box>
      <Typography variant="h5" className={classes.separator}>
        Bank Integrations
      </Typography>
      <BankIntegrationsTable />
    </Paper>
  );
}

export default Content;
