import { createSelector } from "reselect";
import { AppState } from "..";
import { AdminHtmlState } from "../../interfaces";
import { Alert } from "src/interfaces/alert.interface";
import {
  getUnreviewedReturnSales,
  getUnreviewedSales,
} from "src/store/sale/selector";
import { selectUser } from "src/store/system/selector";
import {
  getNewSyncSalesDownloads,
  getNewSyncInventoryDownloads,
} from "src/store/uploads/selector";
import { getNewErrorAlerts } from "src/store/errorAlerts/selector";
import { getTransactions } from "src/store/transaction/selector";
import { getUnreviewedCashActivities } from "src/store/cashActivity/selector";
import {
  getEBayIntegrations,
  getPoshmarkIntegrations,
} from "src/store/plaidIntegration/selector";

const getAdminHtmlState = (state: AppState) => state.adminHtml;

export const adminHtmlSelector = createSelector(
  [getAdminHtmlState],
  (adminHtml: AdminHtmlState) => adminHtml
);

export const activeDialogSelector = createSelector(
  [adminHtmlSelector],
  ({ activeDialog }) => activeDialog || ""
);
export const activeDialogPropsSelector = createSelector(
  [adminHtmlSelector],
  ({ activeDialogProps }) => activeDialogProps
);

export const alertsSelector = createSelector(
  [
    selectUser,
    getUnreviewedSales,
    getNewSyncSalesDownloads,
    getNewSyncInventoryDownloads,
    getNewErrorAlerts,
    getTransactions,
    getUnreviewedCashActivities,
    getUnreviewedReturnSales,
    getEBayIntegrations,
    getPoshmarkIntegrations,
  ],
  (
    user,
    unreviewedSales,
    newSyncSalesDownloads,
    newSyncInventoryDownloads,
    errorAlerts,
    transactionState,
    unreviewedCashActivities,
    unreviewedReturns,
    ebayIntegrations,
    poshmarkIntegrations
  ): Alert[] => {
    const alerts: Alert[] = [];

    if (user?.Plaid_transactions_error)
      alerts.push({
        type: "plaid_error",
        message:
          "There were errors syncing Plaid transactions. Click here to reconnect.",
      });

    if (unreviewedSales.length)
      alerts.push({
        type: "review_sales",
        message: "Click here to review new sales.",
      });

    if (newSyncSalesDownloads.length)
      alerts.push({
        type: "sales",
        message: "You have new eBay transactions ready to import.",
      });

    if (newSyncInventoryDownloads.length)
      alerts.push({
        type: "inventory",
        message: "Click here to review pending inventory.",
      });

    if (errorAlerts?.length) {
      for (const errorAlert of errorAlerts) {
        alerts.push({
          type: "error",
          message: errorAlert.message,
          id: errorAlert.id,
        });
      }
    }

    if (transactionState.unreviewedTransactions.length)
      alerts.push({
        type: "expenses",
        message: "Click here to review new expenses.",
      });

    if (unreviewedCashActivities.length)
      alerts.push({
        type: "cash_activities",
        message: "Click here to review new cash activities.",
      });

    if (unreviewedReturns?.length > 0)
      alerts.push({
        type: "returns",
        message: "Click here to review new returns/refunds/cancellations.",
      });

    for (const integration of ebayIntegrations) {
      if (!integration.error) continue;
      alerts.push({
        integrationId: integration.id,
        type: "ebay_error",
        message: `My Reseller Genie has stopped syncing with your eBay ${
          integration.id === "ebay" ? "" : `${integration.name} `
        }account. Click here to restore the connection.`,
      });
    }

    for (const integration of poshmarkIntegrations) {
      if (integration.error) {
        alerts.push({
          integrationId: integration.id,
          type: "poshmark_error",
          message: `My Reseller Genie has stopped syncing with your ${integration.username} Poshmark account. Click here to restore the connection.`,
        });
      }

      if (!integration.deductionsRemindLater || integration.deductionsRemindLater.toDate() < new Date()) {
alerts.push({
        type: "poshmark_deductions",
        message: "There may be some deductions that you’re missing for Poshmark shipping that are not shown on Poshmark’s website. Click here to review them.",
      });
      }
    }

    return alerts;
  }
);
