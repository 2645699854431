import { createSelector } from "reselect";

import { getVendors } from "src/store/vendor/selector";
import { userGetInventoryTypeSelector } from "src/store/system/selector";
import { getMileage } from "src/pages/TransactionPage/mileage";
import type { TransactionState } from "src/interfaces/transactionState.interface";
import type { AppState } from "..";

export const getTransactionState = (state: AppState) => state.transaction;

export const transactionSelector = createSelector(
  [getTransactionState, userGetInventoryTypeSelector],
  (transaction: TransactionState, inventoryType) => ({
    ...transaction,
    accounts: transaction.accounts.filter(
      (t) => !t.inventoryType || t.inventoryType === inventoryType
    ),
  })
);
export const getTransactions = createSelector(
  [getTransactionState, getVendors],
  (transactions, vendors) => {
    const items = transactions.items.map((item) => {
      if (item.vendor)
        return {
          ...item,
          vendorName: vendors.find((v) => v.id === item.vendor)?.name || "",
        };

      return item;
    });
    const reviewedTransactions = items.filter(
      (item) => item.reviewed || item.reviewed == null
    );
    const unreviewedTransactions = items.filter(
      (item) => !item.reviewed && item.reviewed != null
    );
    return {
      ...transactions,
      items,
      reviewedTransactions,
      unreviewedTransactions,
    };
  }
);

export const defaultGeneralLedgerAccountSelector = createSelector(
  [transactionSelector],
  (transactions) => transactions.accounts
);

export const allIntegrationNamesSelector = createSelector(
  getTransactionState,
  (transactions) => {
    const integrationNames = [
      ...new Set(
        transactions.items
          .map(({ integration_name }) => integration_name)
          .filter(Boolean)
      ),
    ] as string[];
    integrationNames.sort((a, b) => a.localeCompare(b));
    return integrationNames;
  }
);

export const getEarliestTransactionYear = createSelector(
  getTransactionState,
  (transactionsState) => {
    const transactions = transactionsState.items;
    const minDate = transactions.reduce((result, item) => {
      if (!item.date) return result;
      const date = new Date(item.date);
      if (isNaN(date.valueOf())) return result;
      if (date < result) return date;
      return result;
    }, new Date());
    return +minDate.getFullYear();
  }
);

export const mileageForYearSelector = createSelector(
  [getTransactionState, (_, year) => year],
  (transactionState, y) => {
    const year = parseInt(y);
    return transactionState.items.reduce((mileage, transaction) => {
      if (
        transaction.date?.getFullYear() === year &&
        (transaction.account === "Sourcing Mileage/Transportation" ||
          transaction.account === "Non-Sourcing Mileage/Transportation")
      ) {
        return mileage + getMileage(transaction.date, transaction.amount);
      }
      return mileage;
    }, 0);
  }
);

export const haveTransactionsLoadedSelector = createSelector(
  [getTransactionState],
  (transactionState) => transactionState.loaded || false
);
