import React, {
  useState,
  useMemo,
  type Dispatch,
  type SetStateAction,
} from "react";
import range from "lodash/range";
import { useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";

import Button from "@material-ui/core/Button";

import { getEarliestSaleYear } from "src/store/sale/selector";
import { getEarliestTransactionYear } from "src/store/transaction/selector";

import type { ReportParameters } from "../report";
import ScheduleCMappingsDialog from "../ScheduleCReport/MappingsDialog";

export interface ParametersSelectionScheduleCProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
}

export function ParametersSelectionScheduleC({
  parameters,
  setParameters,
}: ParametersSelectionScheduleCProps) {
  const earliestSaleYear = useSelector(getEarliestSaleYear);
  const earliestTransactionYear = useSelector(getEarliestTransactionYear);
  const startYear = Math.min(earliestSaleYear, earliestTransactionYear);
  const currentYear = new Date().getFullYear();
  const allInventoryYears = useMemo(() => {
    return range(startYear, currentYear + 1).map((year) => (
      <MenuItem key={year} value={"" + year}>
        {year}
      </MenuItem>
    ));
  }, [startYear, currentYear]);
  const [openMappings, setOpenMappings] = useState(false);

  return (
    <>
      <Box display="flex" my={1}>
        <FormControl variant="outlined" margin="dense">
          <InputLabel id="year">Select Year</InputLabel>
          <Select
            label="Select Year"
            margin="dense"
            onChange={(e: any) => {
              setParameters((current) => ({
                ...current,
                year: e.target.value,
              }));
            }}
            value={parameters.year || currentYear}
          >
            {allInventoryYears}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body1">
        The Schedule C is a tax form used by many small businesses to report
        their business income and expenses. You can run the schedule C for a
        given year and MRG will add your accounting information into the form
        for you. Simply print and add your personal details to the top of the
        form!
      </Typography>
      <ScheduleCMappingsDialog
        all
        open={openMappings}
        onClose={() => setOpenMappings(false)}
      />
      <Box position="absolute" right="0" bottom="0" mb="-56px">
        <Button onClick={() => setOpenMappings(true)}>View Mappings</Button>
      </Box>
    </>
  );
}
