import { createSelector } from "reselect";
import { AppState } from "..";
import { defaultGeneralLedgerAccountSelector } from "../transaction/selector";
import {
  userGetInventoryTypeSelector,
  scheduleCMappingsSelector,
  scheduleCGroupingsSelector,
} from "../system/selector";

const InventoryPurchaseGeneralLedgerAccount = {
  "General Ledger Account": "Inventory Purchases",
  Type: "Other COGS",
};
const getGeneralLedgerAccountState = (state: AppState) => state.glAccounts;

export const getGeneralLedgerAccounts = createSelector(
  getGeneralLedgerAccountState,
  (state) => state.items
);

export const generalLedgerAccountsSelector = createSelector(
  [
    defaultGeneralLedgerAccountSelector,
    getGeneralLedgerAccounts,
    userGetInventoryTypeSelector,
  ],
  (defaultGeneralLedgerAccounts, userGeneralLedgerAccounts, inventoryType) => {
    return [
      ...(defaultGeneralLedgerAccounts || []),
      ...(inventoryType === "cash"
        ? [InventoryPurchaseGeneralLedgerAccount]
        : []),
      ...(userGeneralLedgerAccounts || []).map((ugla) => ({
        id: ugla.id,
        "General Ledger Account": ugla.name,
        Type: ugla.type,
      })),
    ];
  }
);

export const scheduleCAccountsSelector = createSelector(
  [generalLedgerAccountsSelector],
  (accounts) => {
    const all = [
      {
        "General Ledger Account": "Sales - Transaction Fees",
      },
      {
        "General Ledger Account": "Sales - Shipping Costs",
      },
      ...accounts,
    ];

    all.sort((a, b) =>
      a["General Ledger Account"].localeCompare(b["General Ledger Account"])
    );

    return all;
  }
);

export const scheduleCNotMappedGeneralLedgerAccountsSelector = createSelector(
  [scheduleCMappingsSelector, scheduleCAccountsSelector],
  (scheduleCMappings, generalLedgerAccounts) => {
    const missing: { "General Ledger Account": string; id?: string }[] = [];
    for (const account of generalLedgerAccounts) {
      if (!scheduleCMappings[account["General Ledger Account"]])
        missing.push(account);
    }
    return missing;
  }
);

export const scheduleCAreGroupingsMissingSelector = createSelector(
  [scheduleCMappingsSelector, scheduleCGroupingsSelector],
  (scheduleCMappings, scheduleCGroupings) => {
    let areGroupingsMissing = false;
    let otherExpenses = 0;
    for (const [account, expenseType] of Object.entries(scheduleCMappings)) {
      if (expenseType === "other-expenses") {
        otherExpenses++;
        if (!scheduleCGroupings[account]) {
          areGroupingsMissing = true;
          if (otherExpenses > 9) return true;
        }
      }
    }

    return areGroupingsMissing && otherExpenses > 9;
  }
);
