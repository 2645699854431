export const Rates = [
  // From inclusive, to, exclusive
  // { from: 'YYYY-MM-DD', to: 'YYYY-MM-DD', value: '$/mile' }
  { from: "2025-01-01", to: "2026-01-01", value: 0.70},
  { from: "2024-01-01", to: "2025-01-01", value: 0.67 },
  { from: "2023-01-01", to: "2024-01-01", value: 0.655 },
  { from: "2022-07-01", to: "2023-01-01", value: 0.625 },
  { from: "2022-01-01", to: "2022-07-01", value: 0.585 },
];

function dateToString(date: Date): string {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map((d) => `${d}`.padStart(2, "0"))
    .join("-");
}

export function getRate(date?: Date): number | undefined {
  if (!date) return;

  const d = dateToString(date);

  for (const { from, to, value } of Rates) {
    if (from <= d && d < to) return value;
  }
}

export function getMileage(date?: Date, amount?: number): number {
  if (date && amount) {
    const rate = getRate(date);
    if (rate) return amount / rate;
  }

  return 0;
}
