import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { loadNewSyncSales } from "src/store/sale/actions";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { markErrorAlertViewed } from "src/apiService";
import type {
  Alert,
  AlertEBay,
  AlertEtsy,
  AlertPoshmark,
} from "src/interfaces/alert.interface";
import { getEBayOAuthUrl } from "src/apiService/modules/ebay";
import { etsyRequestAuthorizationCode } from "src/apiService/modules/etsy";
import {
  poshmarkIntegrationUpdate,
  poshmarkIntegrationRemoveErrorFlag,
} from "src/apiService/modules/poshmark";
import { getExtensionId, MissingExtensionError } from "src/utils/extension";
import { getPoshmarkIntegrations } from "src/store/plaidIntegration/selector";
import { getPoshmarkUser } from "src/utils/extension/poshmark";
import PoshmarkClient, {
  NotLoggedError,
} from "src/store/integrationSync/actions/poshmark/PoshmarkClient";

import WalkThrough from "./WalkThroughNotificationsDialog";
import getAlertSelector from "./getAlertSelector";

export interface NotificationsDialogProps {
  open: boolean;
  onClose: () => void;
  items: Alert[];
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    selectedRow: {
      backgroundColor: "#ccccff",
    },
    notificationItem: {
      border: "1px solid #eeeeff",
      minHeight: 60,
      "&:hover": {
        backgroundColor: "#eeeeff",
        cursor: "pointer",
      },
    },
  })
);

function EBayRefreshTokenDialogContent({ integrationId }) {
  useEffect(() => {
    if (!integrationId) return;
    let cancel = false;

    (async () => {
      const params: { integrationId: string; integrationName?: string } = {
        integrationId,
      };

      if (integrationId === "ebay") params.integrationName = "eBay";
      const {
        data: { url },
      } = await getEBayOAuthUrl(params);

      if (cancel) return;

      (window.location as any) = url;
    })();

    return () => {
      cancel = true;
    };
  }, [integrationId]);

  return (
    <DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <CircularProgress />
      </Box>
    </DialogContent>
  );
}

function EBayRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh eBay integration</DialogTitle>
      <EBayRefreshTokenDialogContent integrationId={integrationId} />
    </Dialog>
  );
}

function EtsyRefreshTokenDialogContent({ integrationId }) {
  useEffect(() => {
    if (!integrationId) return;
    let cancel = false;

    (async () => {
      const authResponse = await etsyRequestAuthorizationCode();

      if (cancel) return;
      window.location.href = authResponse.data.url;
    })();

    return () => {
      cancel = true;
    };
  }, [integrationId]);

  return (
    <DialogContent>
      <Box display="flex" justifyContent="center" p={1}>
        <CircularProgress />
      </Box>
    </DialogContent>
  );
}

function EtsyRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh Etsy integration</DialogTitle>
      <EtsyRefreshTokenDialogContent integrationId={integrationId} />
    </Dialog>
  );
}

function PoshmarkRefreshTokenDialogContent({ onClose, integration }) {
  const [error, setError] = useState<string>();
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  useEffect(() => {
    if (!integration) return;
    let cancel = false;

    (async () => {
      let extensionId;

      try {
        try {
          extensionId = getExtensionId();
        } catch (e) {
          if (e instanceof MissingExtensionError) {
            setError(
              "Looks like you don’t have the My Reseller Genie extension installed. Click profile icon > Web Extension and download the extension in order to connect your account."
            );
            return;
          }

          throw e;
        }

        if (!extensionId) {
          setError(
            "Looks like you don’t have the My Reseller Genie extension installed. Click profile icon > Web Extension and download the extension in order to connect your account."
          );
          return;
        }

        const client = new PoshmarkClient();
        let cookieUserId;
        try {
          cookieUserId = await client.getUserId();
        } catch (e) {
          if (e instanceof NotLoggedError) {
            setError("You must be logged in to poshmark");
            return;
          }

          throw e;
        }

        if (cancel) return;

        if (cookieUserId !== integration.uid) {
          setError("You must be logged in to poshmark with the correct user");
          return;
        }

        const data = await getPoshmarkUser();
        if (cancel) return;

        if (data.jwt) {
          await poshmarkIntegrationUpdate({
            integrationId: integration.id,
            jwt: data.jwt,
          });
          if (cancel) return;
          await poshmarkIntegrationRemoveErrorFlag(integration.id);

          if (cancel) return;
          onCloseRef.current();
        }
      } catch (e) {
        setError((e as Error).message);
        return;
      }
    })();

    return () => {
      cancel = true;
    };
  }, [integration]);

  return (
    <DialogContent>
      {error ? (
        <Box mb={2}>
          <MuiAlert severity="error">{error}</MuiAlert>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" p={1}>
          <CircularProgress />
        </Box>
      )}
    </DialogContent>
  );
}

function PoshmarkRefreshTokenDialog({
  integrationId,
  onClose,
}: {
  integrationId?: string;
  onClose: () => void;
}) {
  const integration = useSelector((state: any) => {
    const integrations = getPoshmarkIntegrations(state);
    return integrations.find(({ id }) => id === integrationId);
  });
  return (
    <Dialog open={!!integrationId} onClose={onClose}>
      <DialogTitle>Refresh Poshmark integration</DialogTitle>
      <PoshmarkRefreshTokenDialogContent
        integration={integration}
        onClose={onClose}
      />
    </Dialog>
  );
}

function NotificationsDialogContent({
  onClose,
  items,
}: Omit<NotificationsDialogProps, "open">) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alertDialog, setAlertDialog] = useState<{
    type: "ebay" | "amazon" | "etsy" | "poshmark";
    integrationId: string;
  }>();

  const handleClick = (item: Alert) => {
    switch (item.type) {
      case "plaid_error":
        dispatch(setActiveDialog("update_expense_account"));
        onClose();
        break;

      case "error":
        markErrorAlertViewed(item.id);
        onClose();
        break;

      case "returns":
        dispatch(setActiveDialog("returns_review"));
        onClose();
        break;

      case "inventory":
        dispatch(setActiveDialog("inventory_sync"));
        onClose();
        break;

      case "sales":
        dispatch(loadNewSyncSales());
        dispatch(setActiveDialog("match_sales"));
        onClose();
        break;

      case "review_sales":
        dispatch(setActiveDialog("review_sales"));
        onClose();
        break;

      case "expenses":
        dispatch(setActiveDialog("categorize_expenses"));
        onClose();
        break;

      case "cash_activities":
        dispatch(setActiveDialog("cash_activities"));
        onClose();
        break;

      case "ebay_error":
        setAlertDialog({
          type: "ebay",
          integrationId: (item as AlertEBay).integrationId,
        });
        break;

      case "etsy_error":
        setAlertDialog({
          type: "etsy",
          integrationId: (item as AlertEtsy).integrationId,
        });
        break;

      case "poshmark_error":
        setAlertDialog({
          type: "poshmark",
          integrationId: (item as AlertPoshmark).integrationId,
        });
        break;

      case "poshmark_deductions":
        dispatch(setActiveDialog("poshmark_deductions"));
        break;

      default:
        console.warn("Unhandled type", (item as any).type, item);
        break;
    }
  };

  return (
    <>
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent>
        {items.length ? (
          <List>
            {items.map((item) => (
              <ListItem
                key={JSON.stringify(item)}
                onClick={() => handleClick(item)}
                className={clsx(
                  classes.notificationItem,
                  getAlertSelector(item.type)
                )}
              >
                {item.message}
              </ListItem>
            ))}
          </List>
        ) : (
          <DialogContentText>No current notifications.</DialogContentText>
        )}
      </DialogContent>
      <EBayRefreshTokenDialog
        integrationId={
          alertDialog?.type === "ebay" ? alertDialog.integrationId : undefined
        }
        onClose={() => setAlertDialog(undefined)}
      />
      <EtsyRefreshTokenDialog
        integrationId={
          alertDialog?.type === "etsy" ? alertDialog.integrationId : undefined
        }
        onClose={() => setAlertDialog(undefined)}
      />
      <PoshmarkRefreshTokenDialog
        integrationId={
          alertDialog?.type === "poshmark"
            ? alertDialog.integrationId
            : undefined
        }
        onClose={() => setAlertDialog(undefined)}
      />
    </>
  );
}

function NotificationsDialog({
  open,
  onClose,
  items,
}: NotificationsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <NotificationsDialogContent onClose={onClose} items={items} />
      <WalkThrough alerts={items} />
    </Dialog>
  );
}

export default NotificationsDialog;
