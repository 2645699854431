import React, {
  useMemo,
  useState,
  useEffect,
  type PropsWithChildren,
  type Dispatch,
  type SetStateAction,
} from "react";
import range from "lodash/range";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import DeleteIcon from "@material-ui/icons/Delete";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "react-toastify/dist/ReactToastify.css";

import { getTransactions } from "src/store/transaction/selector";
import { getAllSales } from "src/store/sale/selector";
import { getEarliestSaleYear } from "src/store/sale/selector";
import { getEarliestInventoryYear } from "src/store/inventory/selector";
import { getConsignors } from "src/store/vendor/selector";

import type { ReportParameters, ReportTypes } from "../report";

import { ParametersSelectionScheduleC } from "./ScheduleC";

function useSalesTransactionData() {
  const sales = useSelector(getAllSales);
  const transactionState = useSelector(getTransactions);
  const transactionItems = transactionState?.items;
  return useMemo(() => {
    const platforms = new Set<string>();
    const years = new Set<number>([CurrentYear]);
    if (transactionItems) {
      for (const transaction of transactionItems) {
        const year = transaction?.date?.getFullYear();
        if (year) years.add(year);
        const platform = transaction.integration_name;
        if (platform) platforms.add(platform);
      }
    }

    if (sales) {
      for (const sale of sales) {
        const year = sale.sale_date?.getFullYear();
        if (year) years.add(year);
        const platform = sale.sale_platform;
        if (platform) platforms.add(platform);
      }
    }

    const sortedYears = [...years];
    sortedYears.sort((a: number, b: number) => b - a);
    const sortedPlatforms = [...platforms];
    sortedPlatforms.sort((a: string, b: string) => a.localeCompare(b));
    return {
      years: sortedYears,
      platforms: sortedPlatforms,
    };
  }, [transactionItems, sales]);
}

const useStyles = makeStyles((theme) => ({
  keyboardDatePicker: {
    "& input": {
      width: "6em",
    },
    marginLeft: theme.spacing(1),
  },
  selectPeriod: {
    minWidth: "10em",
  },
  selectYearWrapper: {
    marginLeft: theme.spacing(1),
  },
  selectYear: {
    minWidth: "10em",
  },
  formControlPlatform: {
    marginRight: theme.spacing(1),
  },
  selectPlatform: {
    minWidth: "10em",
  },
}));

const { DefaultPeriod, DefaultYear, CurrentYear } = (() => {
  const now = new Date();
  return {
    DefaultPeriod: `month:${now.getMonth()}`,
    DefaultYear: `year:${now.getFullYear()}`,
    CurrentYear: now.getFullYear(),
  };
})();

interface PeriodPickerProps {
  start?: Date | null;
  end?: Date | null;
  onChange: (
    d: { start: Date } | { end: Date } | { start: Date; end: Date }
  ) => void;
  today?: boolean;
}

function PeriodPicker({
  start,
  end,
  children,
  onChange,
  today = false,
}: PropsWithChildren<PeriodPickerProps>) {
  const classes = useStyles();
  const [reportPeriod, setReportPeriod] = useState(() =>
    start ? `month:${start.getMonth()}` : DefaultPeriod
  );
  const [reportYear, setReportYear] = useState(() =>
    start ? `year:${start.getFullYear()}` : DefaultYear
  );

  return (
    <>
      <FormControl variant="outlined" margin="dense">
        <InputLabel>Period</InputLabel>
        <Select
          className={classes.selectPeriod}
          margin="dense"
          label="Period"
          onChange={(e: React.ChangeEvent<any>) => {
            const value = e.target.value;
            setReportPeriod(value);

            if (value === "year") {
              const startDate = start ? new Date(start) : new Date();
              startDate.setMilliseconds(0);
              startDate.setSeconds(0);
              startDate.setMinutes(0);
              startDate.setHours(0);
              startDate.setDate(1);
              startDate.setMonth(0);

              const endDate = end ? new Date(end) : new Date();
              endDate.setMilliseconds(999);
              endDate.setSeconds(59);
              endDate.setMinutes(59);
              endDate.setHours(23);
              endDate.setDate(1);
              endDate.setMonth(12);
              endDate.setDate(0); // so it returns to previous month

              onChange({
                start: startDate,
                end: endDate,
              });

              return;
            }

            if (value.startsWith("q:")) {
              const q = parseInt(value.substring("q:".length), 10);
              const startDate = start ? new Date(start) : new Date();

              startDate.setMilliseconds(0);
              startDate.setSeconds(0);
              startDate.setHours(0);
              startDate.setMinutes(0);
              startDate.setDate(1);
              startDate.setMonth((q - 1) * 3);

              const endDate = new Date(startDate);
              endDate.setMilliseconds(999);
              endDate.setSeconds(59);
              endDate.setMinutes(59);
              endDate.setHours(23);
              endDate.setDate(1);
              endDate.setMonth(endDate.getMonth() + 3);
              endDate.setDate(0); // So it returns to previous month

              onChange({
                start: startDate,
                end: endDate,
              });

              return;
            }

            if (value.startsWith("month:")) {
              const month = parseInt(value.substring("month:".length), 10);
              const startDate = start ? new Date(start) : new Date();
              startDate.setMilliseconds(0);
              startDate.setSeconds(0);
              startDate.setHours(0);
              startDate.setMinutes(0);
              startDate.setDate(1);
              startDate.setMonth(month);

              const endDate = new Date(startDate);
              endDate.setMilliseconds(999);
              endDate.setSeconds(59);
              endDate.setMinutes(59);
              endDate.setHours(23);
              endDate.setDate(1);
              endDate.setMonth(endDate.getMonth() + 1);
              endDate.setDate(0); // returns to previous

              onChange({
                start: startDate,
                end: endDate,
              });

              return;
            }

            if (value === "today") {
              const startDate = new Date();
              startDate.setMilliseconds(0);
              startDate.setSeconds(0);
              startDate.setMinutes(0);
              startDate.setHours(0);

              const endDate = new Date();
              endDate.setMilliseconds(999);
              endDate.setSeconds(59);
              endDate.setMinutes(59);
              endDate.setHours(23);

              setReportYear(`year:${startDate.getFullYear()}`);

              onChange({
                start: startDate,
                end: endDate,
              });
              return;
            }

            if (value === "custom") {
              setReportYear("custom");
              return;
            }
          }}
          value={reportPeriod}
        >
          <MenuItem value="year">Entire Year</MenuItem>
          {Array(4)
            .fill(0)
            .map((_, i) => {
              const index = i + 1;
              const value = `q:${index}`;
              return (
                <MenuItem key={value} value={value}>
                  Q{index}
                </MenuItem>
              );
            })}
          {Array(12)
            .fill(0)
            .map((_, i) => {
              const value = `month:${i}`;
              const d = new Date();
              d.setDate(1);
              d.setMonth(i);
              return (
                <MenuItem key={value} value={value}>
                  {d.toLocaleDateString("en-US", { month: "short" })}
                </MenuItem>
              );
            })}
          {today ? (
            <MenuItem key="today" value="today">
              Today
            </MenuItem>
          ) : null}
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.selectYearWrapper}
      >
        <InputLabel>Year</InputLabel>
        <Select
          className={classes.selectYear}
          margin="dense"
          label="Year"
          onChange={(e: React.ChangeEvent<any>) => {
            const value = e.target.value;
            setReportYear(value);

            if (value.startsWith("year:")) {
              const year = parseInt(value.substring("year:".length), 10);

              const startDate = start ? new Date(start) : new Date();
              startDate.setFullYear(year);

              const endDate = end ? new Date(end) : new Date();
              endDate.setFullYear(year);

              onChange({
                start: startDate,
                end: endDate,
              });

              return;
            }
          }}
          value={reportYear}
        >
          {children}
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>
      <KeyboardDatePicker
        disableToolbar
        className={classes.keyboardDatePicker}
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        margin="dense"
        label="Start Date"
        autoOk={true}
        value={start || null}
        onChange={(e: any) => {
          setReportPeriod("custom");
          setReportYear("custom");
          onChange({ start: new Date(e) });
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <KeyboardDatePicker
        disableToolbar
        className={classes.keyboardDatePicker}
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        margin="dense"
        label="End Date"
        autoOk={true}
        value={end || null}
        onChange={(e: any) => {
          setReportPeriod("custom");
          setReportYear("custom");
          onChange({ end: new Date(e) });
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </>
  );
}

interface ParametersSelectionProfitLossProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
}

function ParametersSelectionProfitLoss({
  parameters,
  setParameters,
}: ParametersSelectionProfitLossProps) {
  const { years } = useSalesTransactionData();
  const [compareData, setCompareData] = useState(false);

  useEffect(() => {
    return () =>
      setParameters(({ compareStartDate, compareEndDate, ...rest }) => rest);
  }, [setParameters]);

  const handleCompareDataToggle = () => {
    const newCompareData = !compareData;
    setParameters((parameters) => {
      if (!newCompareData) {
        const { compareStartDate, compareEndDate, ...rest } = parameters;
        return rest;
      }

      const start = new Date();
      start.setDate(1);
      start.setHours(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date(start);
      end.setMilliseconds(999);
      end.setSeconds(59);
      end.setHours(23);
      end.setDate(1);
      end.setMonth(end.getMonth() + 1);
      end.setDate(0); // returns to previous

      return {
        ...parameters,
        compareStartDate: start,
        compareEndDate: end,
      };
    });
    setCompareData(newCompareData);
  };

  const yearItems = years.map((year) => {
    const key = `year:${year}`;
    return (
      <MenuItem key={key} value={key}>
        {year}
      </MenuItem>
    );
  });

  return (
    <>
      <Box mt={1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            <PeriodPicker
              today
              start={parameters.startDate}
              end={parameters.endDate}
              onChange={(value) => {
                setParameters((current) =>
                  Object.entries(value).reduce(
                    (p, [k, v]) => {
                      p[k === "start" ? "startDate" : "endDate"] = v;
                      return p;
                    },
                    { ...current }
                  )
                );
              }}
            >
              {yearItems}
            </PeriodPicker>
            <Box>
              <Button onClick={handleCompareDataToggle}>Compare Data</Button>
            </Box>
            {compareData && (
              <Box display="flex" alignItems="center">
                <PeriodPicker
                  today
                  start={parameters.compareStartDate}
                  end={parameters.compareEndDate}
                  onChange={(value) => {
                    setParameters((current) =>
                      Object.entries(value).reduce(
                        (p, [k, v]) => {
                          p[
                            k === "start"
                              ? "compareStartDate"
                              : "compareEndDate"
                          ] = v;
                          return p;
                        },
                        { ...current }
                      )
                    );
                  }}
                >
                  {yearItems}
                </PeriodPicker>
                <IconButton onClick={handleCompareDataToggle}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </>
        </MuiPickersUtilsProvider>
      </Box>
      {!compareData && (
        <Box height={52} display="flex" alignItems="center">
          <Typography variant="body1">
            A profit and loss statement is an accounting report that tells you
            about your income and expenses over a period of time.
          </Typography>
        </Box>
      )}
    </>
  );
}

interface ParametersSelectionSalesTaxProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
}

function ParametersSelectionSalesTax({
  parameters,
  setParameters,
}: ParametersSelectionSalesTaxProps) {
  const { years } = useSalesTransactionData();

  const yearItems = years.map((year) => {
    const key = `year:${year}`;
    return (
      <MenuItem key={key} value={key}>
        {year}
      </MenuItem>
    );
  });

  return (
    <>
      <Box mt={1} mb={1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <PeriodPicker
            today
            start={parameters.startDate}
            end={parameters.endDate}
            onChange={(value) => {
              setParameters((current) =>
                Object.entries(value).reduce(
                  (p, [k, v]) => {
                    p[k === "start" ? "startDate" : "endDate"] = v;
                    return p;
                  },
                  { ...current }
                )
              );
            }}
          >
            {yearItems}
          </PeriodPicker>
        </MuiPickersUtilsProvider>
      </Box>
      <Typography variant="body1">
        A summary of sales tax collected by state and broken out by the ‘Liable
        to Pay’ field
      </Typography>
    </>
  );
}

function ParametersSelectionInventory({ parameters, setParameters }) {
  const earliestSaleYear = useSelector(getEarliestSaleYear);
  const earliestInventoryYear = useSelector(getEarliestInventoryYear);
  const startYear = Math.min(earliestSaleYear, earliestInventoryYear);
  const currentYear = new Date().getFullYear();
  const allInventoryYears = useMemo(() => {
    return range(startYear, currentYear + 1).map((year) => (
      <MenuItem key={year} value={"" + year}>
        {year}
      </MenuItem>
    ));
  }, [startYear, currentYear]);

  return (
    <>
      <Box display="flex" my={1}>
        <FormControl variant="outlined" margin="dense">
          <InputLabel id="year">Select Year</InputLabel>
          <Select
            label="Select Year"
            margin="dense"
            onChange={(e: any) => {
              setParameters((current) => ({
                ...current,
                year: e.target.value,
              }));
            }}
            value={parameters.year || currentYear}
          >
            {allInventoryYears}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body1">
        The inventory report gives you the inventory information used to
        calculate your cost of goods sold for a given year. You'll give this
        info to your accountant or enter these details on your tax forms.
      </Typography>
    </>
  );
}

interface ParametersSelectionPlatformTransactionProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
}

function ParametersSelectionPlatformTransaction({
  parameters,
  setParameters,
}: ParametersSelectionPlatformTransactionProps) {
  const classes = useStyles();
  const { years, platforms } = useSalesTransactionData();

  const yearItems = years.map((year) => {
    const key = `year:${year}`;
    return (
      <MenuItem key={key} value={key}>
        {year}
      </MenuItem>
    );
  });

  return (
    <>
      <Box mt={1}>
        <FormControl
          variant="outlined"
          margin="dense"
          className={classes.formControlPlatform}
        >
          <InputLabel>Platform</InputLabel>
          <Select
            disabled={!platforms.length}
            margin="dense"
            label="Platform"
            className={classes.selectPlatform}
            value={parameters.platform || ""}
            onChange={(e: React.ChangeEvent<any>) => {
              const platform = e.target.value;
              setParameters((p) => ({ ...p, platform }));
            }}
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {platform}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <PeriodPicker
            today
            start={parameters.startDate}
            end={parameters.endDate}
            onChange={(value) => {
              setParameters((current) =>
                Object.entries(value).reduce(
                  (p, [k, v]) => {
                    p[k === "start" ? "startDate" : "endDate"] = v;
                    return p;
                  },
                  { ...current }
                )
              );
            }}
          >
            {yearItems}
          </PeriodPicker>
        </MuiPickersUtilsProvider>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <Typography variant="body1">
          A platform transaction report shows a list of all the transactions for
          the platform you select during the time period you select -- this
          includes sales, returns/refunds/cancellations and expenses.
        </Typography>
      </Box>
    </>
  );
}

interface ParametersSelectionConsignmentCommissionProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
}

function ParametersSelectionConsignmentCommission({
  parameters,
  setParameters,
}: ParametersSelectionConsignmentCommissionProps) {
  const classes = useStyles();
  const { years } = useSalesTransactionData();
  const consignors = useSelector(getConsignors);

  useEffect(() => {
    return () =>
      setParameters(({ compareStartDate, compareEndDate, ...rest }) => rest);
  }, [setParameters]);

  const yearItems = years.map((year) => {
    const key = `year:${year}`;
    return (
      <MenuItem key={key} value={key}>
        {year}
      </MenuItem>
    );
  });

  return (
    <>
      <Box mt={1}>
        <FormControl
          variant="outlined"
          margin="dense"
          className={classes.formControlPlatform}
        >
          <InputLabel>Consignor</InputLabel>
          <Select
            disabled={!consignors.length}
            margin="dense"
            label="Consignor"
            className={classes.selectPlatform}
            value={parameters.consignor?.id || ""}
            onChange={(e: React.ChangeEvent<any>) => {
              const id = e.target.value;
              setParameters((p) => ({
                ...p,
                consignor: consignors.find((c) => c.id === id),
              }));
            }}
          >
            {consignors.map((consignor) => (
              <MenuItem key={consignor.id} value={consignor.id}>
                {consignor.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <PeriodPicker
            today
            start={parameters.startDate}
            end={parameters.endDate}
            onChange={(value) => {
              setParameters((current) =>
                Object.entries(value).reduce(
                  (p, [k, v]) => {
                    p[k === "start" ? "startDate" : "endDate"] = v;
                    return p;
                  },
                  { ...current }
                )
              );
            }}
          >
            {yearItems}
          </PeriodPicker>
        </MuiPickersUtilsProvider>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <Typography variant="body1">
          A consignment commission report will allow you to select a consignor
          (Vendors marked as consignors) and view the sales for a given time
          period that are tied to that consignor. It will show a summary of
          sales, fees, and calculated commission owed to the consignor.
        </Typography>
      </Box>
    </>
  );
}

export interface ParametersSelectionProps {
  parameters: ReportParameters;
  setParameters: Dispatch<SetStateAction<ReportParameters>>;
  reportType: ReportTypes;
}

function ParametersSelection({
  parameters,
  setParameters,
  reportType,
}: ParametersSelectionProps) {
  switch (reportType) {
    case "inventory":
      return (
        <ParametersSelectionInventory
          parameters={parameters}
          setParameters={setParameters}
        />
      );
    case "profit/loss":
      return (
        <ParametersSelectionProfitLoss
          parameters={parameters}
          setParameters={setParameters}
        />
      );

    case "platform-transaction":
      return (
        <ParametersSelectionPlatformTransaction
          parameters={parameters}
          setParameters={setParameters}
        />
      );
    case "consignment-commission":
      return (
        <ParametersSelectionConsignmentCommission
          parameters={parameters}
          setParameters={setParameters}
        />
      );

    case "sales-tax":
      return (
        <ParametersSelectionSalesTax
          parameters={parameters}
          setParameters={setParameters}
        />
      );

    case "schedule-c":
      return (
        <ParametersSelectionScheduleC
          parameters={parameters}
          setParameters={setParameters}
        />
      );
  }

  return null;
}

export default ParametersSelection;
