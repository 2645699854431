import ServiceClient from "src/utils/extension/ServiceClient";
import { getMercariCookies } from "src/utils/extension/mercari";

/*
async function sha256(et) {
    const en = new TextEncoder().encode(et);
    const ed = await window.crypto.subtle.digest("SHA-256", en);
    return Array.from(new Uint8Array(ed)).map(et => et.toString(16).padStart(2, "0")).join("")
}

async function getPersistedQuerySha256Hash(queryDefinition) {
    return await sha256(require('graphql/language').print(queryDefinition))
}
*/


export class NotLoggedError extends Error {
  constructor() {
    super("You must be logged in Mercari to perform the sync process.");
  }
}

export class UnauthorizedError extends Error {
  url: string;
  status: number;
  json: object;

  constructor(operation: string, url: string, status: number, json: object) {
    super(`(mercari) ${operation} Failed (unauthorized error property)`);
    this.url = url;
    this.status = status;
    this.json = json;
  }
}

const MercariURL = "https://www.mercari.com";

export interface UserItemsQueryParameters {
  status?: "sold_out" | "on_sale" | "trading";
  sortBy?: "created" | "updated" | "name" | "price" | "num_likes";
  sortType?: "asc" | "desc";
  page?: number;
}

export const enum MercariItemStatus {
  ForSale = 1,
  InProgress = 2,
  Complete = 3,
}

export const enum MercariSortBy {
  NewestListed = 2,
  LowestPrice = 3,
  HighestPrice = 4,
}

function buildURL(
  base: string,
  path: string,
  searchParams: Record<string, string> = {}
): string {
  const url = new URL(path, base);

  for (const [key, value] of Object.entries(searchParams)) {
    url.searchParams.set(key, value);
  }

  return url.toString();
}

function checkErrorsProperty(
  operation: string,
  url: string,
  status: number,
  json: any
) {
  if (json.errors) {
    if (json.data) {
      console.warn(
        `(mercari) ${operation} warn (errors property, but we've got data)`,
        {
          url,
          status,
          json,
        }
      );
    } else {
      console.error(`(mercari) ${operation} failed (errors property)`, {
        url,
        status,
        json,
      });

      if (
        Array.isArray(json.errors) &&
        json.errors.some(
          (e) => e.status === 401 || e.message === "Unauthorized"
        )
      )
        throw new UnauthorizedError(operation, url, status, json);
      throw new Error(`(mercari) ${operation} Failed (errors property)`);
    }
  }
}

class MercariClient extends ServiceClient {
  async getCredentials(): Promise<{ accessToken: string; userId: string }> {
    const cookies = await getMercariCookies(this.extensionId);
    const mwus = cookies.find((c) => c.name === "_mwus")?.value;
    if (!mwus) throw new NotLoggedError();
    let payload: { accessToken: string; userId: string };
    try {
      payload = JSON.parse(atob(mwus)); // accessToken, refreshToken, csrfSecret, userId
    } catch (e) {
      throw new Error("Invalid poshmark JWT", e as Error);
    }

    if (!payload.userId) throw new NotLoggedError();
    return payload;
  }

  // https://www.mercari.com/transaction/order_status/m60269131132/
  // The web sends this as a POST request
  async orderStatusConsolidated(referenceId: string) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "OrderStatusConsolidated",
      variables: JSON.stringify({
        input: {
          referenceId,
          referenceType: "item",
        },
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "cf7fb5baaa7fc84f8fe8a4c8aa8463553655956cecf5d803becb653afe572a57",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
        "X-Platform": "web",
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) orderStatusConsolidated failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) orderStatusConsolidated Failed (status code)");
    }

    const json = res.data;
    checkErrorsProperty("orderStatusConsolidated", url, res.status, json);

    return json.data.orderStatus.contents;
  }

  // https://www.mercari.com/mypage/listings/active/
  async userItemsQuery({
    status = "sold_out",
    sortBy = "created",
    sortType = "desc",
    page = 1,
  }: UserItemsQueryParameters = {}) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "userItemsQuery",
      variables: JSON.stringify({
        userItemsInput: {
          sellerId: credentials.userId,
          status,
          sortBy,
          sortType,
          page,
        },
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "e727ab22e996b3161438e0644485f2ea329dff2bbb6ad9c65f309710d2c4bf28",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) userItemsQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) userItemsQuery Failed (status code)");
    }

    const json = res.data;
    checkErrorsProperty("userItemsQuery", url, res.status, json);

    if (!json.data.userItems) {
      console.error(
        "(mercari) userItemsQuery failed (no data.userItems property)",
        {
          url,
          json,
        }
      );

      throw new Error(
        "(mercari) userItemsQuery Failed (no data.userItems property)"
      );
    }

    return json.data.userItems;
  }

  async *userItemsQueryFull(args?: Omit<UserItemsQueryParameters, "page">) {
    for (let page = 1; ; page++) {
      const res = await this.userItemsQuery({ ...args, page });

      yield {
        sales: res.items,
        pagination: res.pagination,
      };

      if (!res.pagination.hasNext) break;
    }
  }

  // https://www.mercari.com/us/item/m60269131132/
  async productQuery(id: string) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "productQuery",
      variables: JSON.stringify({
        id,
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "dd7d7f1f07a201cd2bd0e4f49e0e67618d0ff4d4114f043d3263c855d5e25565",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) productQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
        id,
      });

      throw new Error("(mercari) productQuery Failed (status code)");
    }

    const json = res.data;
    checkErrorsProperty("productQuery", url, res.status, json);

    return json.data.item;
  }

  // https://www.mercari.com/u/user292277823/
  async publicProfileSearchQuery({
    sellerId,
    length = 100,
    offset = 0,
    itemStatuses = [MercariItemStatus.ForSale],
    query = "",
    sortBy = MercariSortBy.NewestListed,
  }: {
    sellerId: number;
    length?: number;
    offset?: number;
    itemStatuses?:
      | [MercariItemStatus.ForSale]
      | [MercariItemStatus.InProgress, MercariItemStatus.Complete] // Sold
      | [
          MercariItemStatus.ForSale,
          MercariItemStatus.InProgress,
          MercariItemStatus.Complete
        ];
    query?: string;
    sortBy?: MercariSortBy;
  }) {
    const credentials = await this.getCredentials();
    const url = buildURL(MercariURL, "/v1/api", {
      operationName: "publicProfileSearchQuery",
      variables: JSON.stringify({
        criteria: {
          sellerIds: [sellerId],
          offset,
          itemStatuses,
          query,
          sortBy,
          facets: [1, 2, 3, 9, 18],
          hashtags: [],
        },
        userId: sellerId,
      }),
      extensions: JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash:
            "14cc8036f7bfe49c0115d5faac36cfe0420571bf241583acf00eda5143d8e853",
        },
      }),
    });
    const res = await this.fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    });

    if (!res.ok) {
      console.error(res);
      console.error("(mercari) publicProfileSearchQuery failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error(
        "(mercari) publicProfileSearchQuery Failed (status code)"
      );
    }

    const json = res.data;
    checkErrorsProperty("publicProfileSearchQuery", url, res.status, json);

    return json.data.search; // { itemList, page: { count } }
  }

  async *publicProfileSearchQueryFull() {
    let offset = 0;
    let count = 0;
    const sellerId = parseInt((await this.getCredentials()).userId, 10) || -1;

    do {
      const res = await this.publicProfileSearchQuery({ sellerId, offset });
      count = res.count;

      if (!res?.itemsList?.length) return;

      yield {
        items: res.itemsList,
        pagination: {
          count,
          offset,
        },
      };

      offset += res.itemsList.length;
    } while (offset < count);
  }

  async initialize() {
    const url = buildURL(MercariURL, "/v1/initialize");
    const res = await this.fetch(url);
    if (!res.ok) {
      console.error(res);
      console.error("(mercari) initialize failed (status code)", {
        url,
        body: res.data,
        status: res.status,
      });

      throw new Error("(mercari) initialize Failed (status code)");
    }

    const json = res.data;
    checkErrorsProperty("initialize", url, res.status, json);
    return json;
  }
}

export default MercariClient;
