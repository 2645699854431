import { capitalize } from 'src/apiService/modules/analyticsGroupings';
import { createSelector } from "reselect";
import { AppState } from "..";
import type { TableConfiguration, AnalyticsGroupingItem, FeatureFlag } from 'src/interfaces/systemState.interface';

const getSystemState = (state: AppState) => state.system;

const COUPON_ERROR = "Coupon code not found.";

export const isLoggedInSelector = createSelector(getSystemState, (system) => system.loggedIn);
export const loadingSelector = createSelector(getSystemState, (system) => system.loading);
export const loginVerifiedSelector = createSelector(getSystemState, (system) => system.verifiedLogin);
export const verifyingLoginSelector = createSelector(getSystemState, (system) => system.verifyingLogin);
export const couponErrorSelector = createSelector(getSystemState, (system) => {
	if (system.error === COUPON_ERROR) {
		return system.error;
	}
	return "";
});

export const authorizingSelector = createSelector(getSystemState, (system) => system.authorizing);
export const paymentMethodSelector = createSelector(getSystemState, (system) => !!system.user && (!system.user.pm_id || !system.user.plan));
export const selectUser = createSelector(getSystemState, (system) => system.user);
export const userPlanSelector = createSelector(getSystemState, (system) => system.user?.plan);
export const userPlatformsSelector = createSelector(getSystemState, (system) => system.user?.platforms);
export const userIncomeTaxRateSelector = createSelector(getSystemState, (system) => system.user?.income_tax_rate);
export const userCustomerIdSelector = createSelector(getSystemState, (system) => system.user?.customer_id);
export const userErrorSelector = createSelector(getSystemState, (system) => system.user?.noError);
export const userTosSelector = createSelector(getSystemState, (system) => system.user?.acceptedTOS);

export const submittingFeedbackSelector = createSelector(getSystemState, (system) => system.submitting);
export const submittedFeedbackSelector = createSelector(getSystemState, (system) => system.submitted);
export const systemError = createSelector(getSystemState, (system) => system.error);
export const ebayIntegrationSelector = createSelector(getSystemState, (system) => system.ebayIntegration);
export const tapfiliateSelector = createSelector(getSystemState, (system) => system.signupSuccess);
export const expenseRulesSelector = createSelector(selectUser, (user) =>
  (user?.expense_rules || [])
    .map((r) => JSON.parse(r))
    .filter((r) => r.plaid_description)
);
export const tablesConfigurationSelector = createSelector(selectUser, (user) =>
  Object.entries(user?.tables_configuration || {}).reduce((o, [k, v]) => {
    o[k] = JSON.parse(v);
    return o;
  }, {}) as Record<string, TableConfiguration>
);

export const analyticsGroupingsSelector = createSelector(
  selectUser,
  (user) => user?.analytics_groupings || {}
);

export const analyticsGroupingsListSelector = createSelector(
  analyticsGroupingsSelector,
  (analyticsGroupings) => {
    const list: AnalyticsGroupingItem[] = [];

    for (const department of Object.keys(analyticsGroupings)) {
      for (const category of Object.keys(analyticsGroupings[department])) {
        for (const subcategory of analyticsGroupings[department][category]) {
          list.push({
            department: capitalize(department),
            category: capitalize(category),
            subcategory: capitalize(subcategory),
          });
        }
      }
    }

    return list;
  }
);

export const userIdSelector = createSelector(selectUser, (user) => user?.uid || '');
export const allowedEBayIntegrationsSelector = createSelector(selectUser, (user) => user?.allowed_ebay_integrations || 1);
export const userSalesQuickViewSelector = createSelector(selectUser, (user) => user?.salesQuickView !== false);
export const userGetStartedStepSelector = createSelector(selectUser, (user) => user?.getStartedStep || undefined);
export const userGetInventoryTypeSelector = createSelector(selectUser, (user) => user?.inventoryType || "accrual");
export const userGetStartedHasFinishedSelector = createSelector(userGetStartedStepSelector, (step) => step === "complete-set-up");
export const userChecklistCompletedSelector = createSelector(selectUser, (user) => user?.checklist_completed || false);
export const userShowSetUpFeatureSelector = createSelector(
  selectUser,
  (user) => {
    if (!user) return false;
    return (
      ("checklist_completed" in user || "checklist_items" in user) &&
      !("inventoryType" in user)
    );
  }
);
export const userDontShowDialogsSelector = createSelector(selectUser, (user) => {
  if (!user) return;
  return user.dontShowDialogs || [];
});

export const featureFlagSelector = createSelector(
  [(_, key: FeatureFlag) => key, selectUser],
  (key, user) => {
    return user?.featureFlags?.[key];
  }
);

export const scheduleCMappingsSelector = createSelector([selectUser], (user) => {
  return user?.scheduleCMappings || {};
});

export const scheduleCGroupingsSelector = createSelector([selectUser], (user) => {
  return user?.scheduleCGroupings || {};
});
