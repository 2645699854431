import React, { useState, useEffect, useRef } from "react";
import { useStore, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import {
  haveTransactionsLoadedSelector,
  mileageForYearSelector,
} from "src/store/transaction/selector";
import {
  scheduleCNotMappedGeneralLedgerAccountsSelector,
  scheduleCAreGroupingsMissingSelector,
} from "src/store/glAccounts/selector";

import ScheduleCMappingsDialog from "./MappingsDialog";
import InputsDialog from "./InputsDialog";

import { type ScheduleCReport as ScheduleCReportType } from "../report";
import type { State, ReportResult } from "./types";
import { renderReport } from "./report";

export interface ScheduleCReportProps {
  report: ScheduleCReportType;
  onClearReport: () => void;
}

const InitialState: State = {
  inputsDialogOpen: true,
  deduction: {
    open: true,
    amount: "",
    home: "",
    business: "",
  },
  vehicle: {
    open: true,
    when: "",
    business: "",
    commuting: "",
    other: "",
    personalUseOffDuty: "",
    anotherVehiclePersonalUse: "",
    evidence: "",
    writtenEvidence: "",
  },
};

function ScheduleCReport({ report, onClearReport }: ScheduleCReportProps) {
  const store = useStore();
  const [state, setState] = useState<State>(() => ({
    ...InitialState,
    vehicle: {
      ...InitialState.vehicle,
      business: mileageForYearSelector(
        store.getState(),
        report.parameters.year
      ),
    },
  }));

  const [result, setResult] = useState<ReportResult | null>(null);

  useEffect(() => {
    setState((s) => ({ ...s, inputsDialogOpen: true }));
  }, [report]);

  const handleRunReport = async () => {
    setState((s) => ({ ...s, inputsDialogOpen: false }));
    const result = await renderReport(store, report.parameters.year, state);
    setResult(result);
  };

  return (
    <>
      <InputsDialog
        state={state}
        onClose={() => onClearReport()}
        onRunReport={handleRunReport}
        onChange={setState}
        year={report.parameters.year}
      />
      {result ? (
        <>
          {result.errors.map((error, i) => (
            <Box pt={2}>
              <Alert key={i} severity={error.severity}>
                {error.message}
              </Alert>
            </Box>
          ))}
          {result.url ? (
            <Box
              mt={2}
              width="100%"
              height="calc(100vh - 80px)"
              component="iframe"
              title="Schedule C Report"
              {...({ src: result.url } as any)}
            />
          ) : null}
        </>
      ) : (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          height={128}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

function shouldOpenMappingsDialogSelector(state: any) {
  const generalLedgerAccounts =
    scheduleCNotMappedGeneralLedgerAccountsSelector(state);
  const areGroupingsMissings = scheduleCAreGroupingsMissingSelector(state);
  return generalLedgerAccounts.length > 0 || areGroupingsMissings;
}

function ScheduleCReportWrapper(props: ScheduleCReportProps) {
  const store = useStore();
  const storeRef = useRef(store);
  storeRef.current = store;
  const haveTransactionLoaded = useSelector(haveTransactionsLoadedSelector);
  const initialShouldOpenMappingsDialog = useSelector(
    shouldOpenMappingsDialogSelector
  );
  const [shouldOpenMappingsDialog, setShouldOpenMappingsDialog] = useState(
    initialShouldOpenMappingsDialog
  );

  useEffect(() => {
    const shouldOpenMappingsDialog = shouldOpenMappingsDialogSelector(
      storeRef.current.getState()
    );
    if (shouldOpenMappingsDialog)
      setShouldOpenMappingsDialog(shouldOpenMappingsDialog);
  }, [props.report]);

  if (!haveTransactionLoaded)
    return (
      <Box display="flex" justifyContent="center" m={4}>
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <ScheduleCMappingsDialog
        open={shouldOpenMappingsDialog}
        onClose={() => {
          setShouldOpenMappingsDialog(false);
        }}
      />
      {shouldOpenMappingsDialog ? null : <ScheduleCReport {...props} />}
    </>
  );
}

export default ScheduleCReportWrapper;
