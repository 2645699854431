import firebase from "firebase/app";
import "firebase/storage";
import { getUserId } from "../../config";
import firestore from "src/apiService/firestore";
import type { ExpenseType } from "src/interfaces/systemState.interface";

export const acceptUserTerms = () => {
  const acceptUserTerms = firebase.functions().httpsCallable("updateUsersTOS");
  return getUserId().then((userId) => {
    return acceptUserTerms({ userId: userId })
      .then((result) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  });
};

export async function increaseTourCounter() {
  const userId = await getUserId();
  const db = firestore();
  await db
    .collection("Users")
    .doc(userId)
    .update({ tour_count: firebase.firestore.FieldValue.increment(1) });
}

export async function updateScheduleCMappings(
  scheduleCMappings: Record<string, ExpenseType>, // acountName = expense type
  scheduleCGroupings: Record<string, string>, // accountName = group name
  { replace = false } = {}
) {
  const userId = await getUserId();
  const db = firestore();
  if (replace) {
    await db
      .collection("Users")
      .doc(userId)
      .update({ scheduleCMappings, scheduleCGroupings });
  } else {
    await db.runTransaction(async (t) => {
      const doc = db.collection("Users").doc(userId);
      const user = await t.get(doc);
      const mappings = {
        ...user.data()?.scheduleCMappings,
        ...scheduleCMappings,
      };

      for (const [key, value] of Object.entries(mappings)) {
        if (!value) delete mappings[key];
      }

      const groupings = {
        ...user.data()?.scheduleCGroupings,
        ...scheduleCGroupings,
      };

      for (const [key, value] of Object.entries(groupings)) {
        if (!value || mappings[key] !== "other-expenses") delete groupings[key];
      }
      await t.update(doc, {
        scheduleCMappings: mappings,
        scheduleCGroupings: groupings,
      });
    });
  }
}
